// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		frameworks_loading: false,

		suggestions_updated_showing: false,
		suggestion_computing: false,
		candidates_to_show_params: {
			min_n: 3,
			max_n: 8,
			threshold: 400,
			max_in_memory: 100,
		},
		ed_level_tolerance: 1,

		debug: false,
	}},
	computed: {
		...mapState(['grades', 'alt_grades', 'calculated_ai_simscores']),
		...mapGetters([]),

		resource_alignment_validation: {
			get() { return this.$store.state.lst.resource_alignment_validation },
			set(val) { this.$store.commit('lst_set', ['resource_alignment_validation', val]) }
		},

		comp_factors: {
			get() {
				let s = this.$store.state.lst.align_comp_factors
				if (s) return JSON.parse(s)
				else return {
					// this is for whether or not we're taking education level into account
					education_level: true,

					// this is whether we're using the title, description, or 'text' (practically, keywords) from item metadata
					title: true,
					description: true,
					text: true,

					// existing_alignments: true,
					// this is whether we're using sme_assocs and/or ai_assocs for other alignments
					sme_assocs: true,
					ai_assocs: true,
				}
			},
			set(val) {
				this.$store.commit('lst_set', ['align_comp_factors', JSON.stringify(this.comp_factors)])
			},
		},

		// these return true IFF we're considering (one or more of the item metadata fields) / (one or more of the existing alignment options)
		comp_factor_item() { 
			return (this.comp_factors.title || this.comp_factors.description || this.comp_factors.text) 
				&& this.comp_factor_weights.item > 0
		},
		comp_factor_assocs() { 
			return (this.comp_factors.sme_assocs || this.comp_factors.ai_assocs) 
				&& this.comp_factor_weights.assocs > 0
		},

		// weights for calculating overall sim scores
		item_assoc_weight_pct: {
			// this will be the percentage to weight item metadata in relation to assoc metadata
			// technically, the value will go from 0-12, and we'll scale from there
			get() { return this.$store.state.lst.resource_set_item_assoc_weight_pct },
			set(val) { this.$store.commit('lst_set', ['resource_set_item_assoc_weight_pct', val]) }
		},
		comp_factor_weights() {
			// note: see below for how we weight based on education_level mismatches 
			return {
				item: 1000 - Math.round(1000 * (this.item_assoc_weight_pct / 12)),
				assocs: Math.round(1000 * (this.item_assoc_weight_pct / 12)),
			}
		},
		comp_factor_total_weight() {
			let x = 0
			if (this.comp_factor_item) x += this.comp_factor_weights.item
			if (this.comp_factor_assocs) x += this.comp_factor_weights.assocs
			return x
		},
		scaled_comp_factor_weights() {
			return {
				item: Math.round(this.comp_factor_weights.item / this.comp_factor_total_weight * 100),
				assocs: Math.round(this.comp_factor_weights.assocs / this.comp_factor_total_weight * 100),
			}
		},
		
		// TODO: expand this and make it configurable...
		limiters: {
			get() { 
				let o = this.$store.state.lst.align_limiters[this.tba_framework_identifier] ?? {}
				if (empty(o.item_types)) o.item_types = []
				if (empty(o.lowest_ancestor)) o.lowest_ancestor = null
				if (empty(o.include_branches)) o.include_branches = []
				if (empty(o.exclude_branches)) o.exclude_branches = []
				return o
			},
			set(val) { 
				// this isn't actually getting called... currently we're manually updating this with 
				this.$store.commit('lst_set_hash', ['align_limiters', this.tba_framework_identifier, val]) 
			}
		},		

		base_framework_identifier: {
			get() { 
				return this.$store.state.lst.align_base_framework_identifier[this.tba_framework_identifier] ?? '' 
			},
			set(val) { this.$store.commit('lst_set_hash', ['align_base_framework_identifier', this.tba_framework_identifier, val]) }
		},
		base_framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.base_framework_identifier) },

		suggestion_factors_string() { return JSON.stringify([this.comp_factors, this.comp_factor_weights, this.limiters]) },

		// 'to-be-aligned' framework_identifier
		tba_framework_identifier: {
			get() { return this.$store.state.lst.resource_set_tba_framework_identifier_hash[this.resource_set_id] },
			set(val) { 
				this.$store.commit('lst_set_hash', ['resource_set_tba_framework_identifier_hash', this.resource_set_id, val])
				// whenever this is changed, we need to do some rejiggering
				this.configure_for_tba_framework_identifier()
			}
		},
		tba_framework_record() { return this.framework_records.find(x=>x.lsdoc_identifier == this.tba_framework_identifier) },
		tba_framework_record_descriptor() {
			if (!this.tba_framework_record) return ''
			let s = this.tba_framework_record.json.CFDocument.title
			if (this.tba_framework_record.ss_framework_data.category) {
				let category_data = U.parse_framework_category(this.tba_framework_record.ss_framework_data.category)
				s += ` [${category_data.title}]`
			}
			return s
		},
		tba_available_item_types() { return this.tba_framework_record?.cfo?.item_types },
		tba_item_type_counts() {
			if (!this.tba_framework_record?.cfo) return 0
			let o = {'[no item type]':0}
			for (let identifier in this.tba_framework_record.cfo.cfitems) {
				let item_type = U.item_type_string(this.tba_framework_record.cfo.cfitems[identifier]) || '[no item type]'
				if (empty(o[item_type])) o[item_type] = 0
				++o[item_type]
			}
			return o
		},
	},
	watch: {
	},
	methods: {
		report_ms(str) {
			if (!this.debug) return

			let ts = new Date().getTime()
			if (window.last_ts) str += `  [${((ts - window.last_ts) / 1000).toFixed(4)}]`
			console.log(str)
			window.last_ts = ts
		},

		async configure_for_tba_framework_identifier() {
			this.frameworks_loading = true
			
			// console.warn(`configure_for_tba_framework_identifier (${this.tba_framework_identifier}): ${this.tba_framework_record?.framework_json_loaded}`)

			// load tba framework (and its vectors) if not already loaded
			if (this.tba_framework_identifier) {
				let fr = await U.load_framework_and_cfo(this.tba_framework_identifier, {load_vectors: true, show_loader: true})
				
				// if no framework record found for tba_framework_identifier, it's invalid (this can happen in testing)
				if (!fr) {
					this.tba_framework_identifier = ''
					return
				}

				// make sure limiters.item_types includes only available item types
				let arr = []
				for (let t of this.tba_available_item_types) {
					if (this.limiters.item_types.includes(t)) arr.push(t)
				}
				this.limiters.item_types = arr
			}

			this.frameworks_loading = false
		},

		// as of now, at least, this needs be called any time limiters (currently item_types and include_branches) are changed
		limiters_updated() {
			this.$store.commit('lst_set_hash', ['align_limiters', this.tba_framework_identifier, this.limiters])
		},

		set_tba_framework(framework_identifier) {
			this.tba_framework_identifier = framework_identifier
			U.add_to_recent_frameworks(framework_identifier)
		},

		clear_tba_framework() {
			this.tba_framework_identifier = ''
		},

		async load_crosswalk_framework(fi1, fi2) {
			// check to see if the two frameworks have a crosswalk framework, and if so load it if necessary
			let cfr = U.get_crosswalk_framework_record(fi1, fi2)
			if (cfr && !cfr.framework_json_loaded) {
				await this.$store.dispatch('get_lsdoc', cfr.lsdoc_identifier)
				// from crosswalks, we have to process each of the CFAssociations
				for (let i = 0; i < cfr.json.CFAssociations.length; ++i) {
					cfr.json.CFAssociations[i] = new CFAssociation(cfr.json.CFAssociations[i])
				}

				// then build the cfo for the framework?? (I don't know if we really need to do this)
				let cfo = await U.build_cfo(this.$worker, cfr.json, 'no_loading')
				this.$store.commit('set', [cfr, 'framework_json_loading', false])
				this.$store.commit('set', [cfr, 'cfo', cfo])
			}

			// return the cfr
			return cfr
		},

		comp_factors_changed(param, val) {
			// console.log('comp_factors_changed', this.comp_factors)
			this.$store.commit('lst_set', ['align_comp_factors', JSON.stringify(this.comp_factors)])
		},

		// this is the overarching fn for generating an alignment suggestion array (ASA) for a resource
		async get_resource_alignment_suggestions(resource) {
			this.suggestions_updated_showing = false
			this.suggestion_computing = true

			// if we're still loading base frameworks, pause while that completes
			if (this.frameworks_loading) {
				await U.wait_for_condition(()=>this.frameworks_loading == false, 50, 12000)
			}

			// -----------------------------------------------
			// if we're using the resource metadata for comparison, get vectors for the resource if we haven't already
			if (this.comp_factor_item) {
				await this.get_resource_vectors(resource)
			}
			// console.warn('comp_string_vectors:', resource.comp_string_vectors)

			// -----------------------------------------------
			// Now get list of alignments from this resource to other items that we want to consider
			let alignments_for_matching = []
			if (this.comp_factor_assocs && resource.alignments.length > 0) {
				alignments_for_matching = await this.get_resource_alignments_for_matching(resource)
			}
			// save alignments_for_matching to the resource
			this.$store.commit('set', [resource, 'alignments_for_matching', alignments_for_matching])
			// console.warn('alignments_for_matching:', resource.alignments_for_matching)

			// -----------------------------------------------
			// Now recursively process alignment candidates, starting with either the whole framework's tree, or the designated ancestor(s)
			this.$store.commit('set', [resource, 'candidates', []])
			this.processed_node_count = 0
			if (this.limiters.include_branches.length == 0) {
				++this.processed_node_count
				this.process_node_for_alignment(this.tba_framework_record.cfo.cftree, resource, 0)
			} else {
				for (let identifier of this.limiters.include_branches) {
					++this.processed_node_count
					let node = this.tba_framework_record.cfo.cfitems[identifier].tree_nodes[0]
					this.process_node_for_alignment(node, resource, 0)
				}
			}

			// -----------------------------------------------
			// calculate the simscore for each candidate
			let arr = []
			for (let candidate of resource.candidates) {
				let fs = ''	// this will hold text for a tooltip that shows how the match score is calculated
				// note if the candidate is currently aligned
				candidate.currently_aligned = (resource.alignments.findIndex(x=>x.item_identifier == candidate.cfitem.identifier) > -1)

				// combine the factors into one number
				let num = 0, den = 0
				if (this.comp_factor_item) {
					let numf = candidate.comps.item * this.comp_factor_weights.item
					num += numf
					den += this.comp_factor_weights.item
					fs += `<div class="k-resource-factor-tooltip"><nobr class="k-resource-factor-description">Resource MD:</nobr><v-spacer></v-spacer><nobr>${candidate.comps.item} &times; ${this.scaled_comp_factor_weights.item}% =</nobr><nobr class="k-resource-factor-total">${Math.round(numf/this.comp_factor_total_weight)}</nobr></div>`
				}
				if (this.comp_factor_assocs) {
					let numf = candidate.comps.assocs * this.comp_factor_weights.assocs
					num += numf
					den += this.comp_factor_weights.assocs
					fs += `<div class="k-resource-factor-tooltip"><nobr class="k-resource-factor-description">Cross-Assocs:</nobr><v-spacer></v-spacer><nobr>${candidate.comps.assocs} &times; ${this.scaled_comp_factor_weights.assocs}% =</nobr><nobr class="k-resource-factor-total">${Math.round(numf/this.comp_factor_total_weight)}</nobr></div>`
				}

				// if we don't have any factors to consider and it's not already aligned, it's not a candidate!
				if (den == 0 && !candidate.currently_aligned) {
					continue
				}

				let val = (den == 0) ? 0 : num / den

				// now scale by education_level mismatch if using
				if (this.comp_factors.education_level) {
					// candidate.comps.education_level > 0
					// for a value of 600, we reduce to 335 for a mismatch of 1, 207 for a mismatch of 2, or 137 for a mismatch of 3
					let ed_factor = (candidate.comps.education_level == -1) ? 1 : (1/`1.${candidate.comps.education_level}`).toFixed(2)
					let original_val = val
					val = Math.pow(val, ed_factor)
					// convert to string for fs
					if (ed_factor == 1) ed_factor = 'none'
					else ed_factor = `-${Math.round((1 - (val / original_val)) * 100)}%`
					// else ed_factor = `-${Math.round((1-ed_factor)*100)}%`
					fs += `<div class="k-resource-factor-tooltip mt-1"><nobr class="k-resource-factor-description">Ed. Level Correction:</nobr><nobr class="k-resource-factor-total">${ed_factor}</nobr></div>`
				}

				candidate.simscore = Math.round(val)
				candidate.simscore_pct = candidate.simscore / 10
				candidate.factor_string = fs

				arr.push(candidate)
			}

			// sort candidates by simscore
			arr.sort((a,b)=>b.simscore - a.simscore)

			// store at most max_in_memory candidates -- unless resource_alignment_validation is on, in which case we don't want to limit this
			if (this.resource_alignment_validation != true) arr.splice(this.candidates_to_show_params.max_in_memory, 10000000)

			// store updated candidates array, along with string representation of comp_factors and limiters, in resource
			this.$store.commit('set', [resource, 'candidates', arr])
			this.$store.commit('set', [resource, 'candidate_params_string', this.calculate_candidate_params_string(resource)])

			// determine number of candidates to show by default
			this.$store.commit('set', [resource, 'candidates_showing', 0])
			this.set_candidates_showing(resource, 0)

			// flash indicator that a suggestion has been updated
			this.suggestion_computing = false
			if (!this.suggestions_updated_showing) {
				this.suggestions_updated_showing = true
				setTimeout(x=>{this.suggestions_updated_showing = false}, 2500)
			}

			// DONE WITH GETTING THIS RESOURCE'S SUGGESTIONS
		},

		// this is the fn that gets the LLM vectors for resource metadata (if we don't already have them)
		async get_resource_vectors(resource) {
			// construct text for resource metadata vectors
			let comp_strings = [], has_comp_strings = false
			if (this.comp_factors.title && resource.resource_title) {
				comp_strings[0] = U.normalize_string_for_sparkl_bot(resource.resource_title)
				has_comp_strings = true
			}
			if (this.comp_factors.description && resource.description) {
				comp_strings[1] = U.normalize_string_for_sparkl_bot(resource.description)
				has_comp_strings = true
			}
			if (this.comp_factors.text && resource.text) {
				comp_strings[2] = U.normalize_string_for_sparkl_bot(resource.text)
				has_comp_strings = true
			}

			// if we don't have any comp strings for the resource, move on
			if (!has_comp_strings) {
				this.$store.commit('set', [resource, 'comp_strings', ''])
				this.$store.commit('set', [resource, 'comp_string_vectors', []])
				return

			}
			
			let css = comp_strings.join('XXX')

			// if we already have the vectors, we don't have to look them up again; just return
			if (css == resource.comp_strings && !empty(resource.comp_string_vectors)) {
				return
			}

			// else get the vectors for the comp string
			try {
				// some of the comp_strings might be empty; we can't send those
				let strings = []
				for (let s of comp_strings) if (s) strings.push(s)
				// get SB Vectors
				let payload = {
					service_url: 'sparkl_bot_vectors',
					strings: JSON.stringify(strings)
				}

				let result = await this.$store.dispatch('service', payload)
				// console.log('vectors', result.vectors)
				if (result.vectors) {
					// we got the vectors, so store them
					let vectors = []
					for (let s of comp_strings) vectors.push(s ? result.vectors.shift() : null)
					this.$store.commit('set', [resource, 'comp_strings', css])
					this.$store.commit('set', [resource, 'comp_string_vectors', vectors])
				} else {
					console.error('error getting vectors for resource metadata (1)')
					return
				}
			} catch(err) {
				console.error('error getting vectors for resource metadata (2)', err)
				return
			}
		},

		// this is the fn that gets cross-alignments that can be used to help with aligning to the TBA framework
		async get_resource_alignments_for_matching(resource) {
			let arr = []
			for (let alignment of resource.alignments) {
				// if the alignment is to the framework we're currently aligning to, skip it
				if (alignment.framework_identifier == this.tba_framework_identifier) continue

				// if base_item_for_alignment is set and the alignment isn't to that item, skip it
				if (!empty(this.base_item_for_alignment) && alignment.item_identifier != this.base_item_for_alignment.identifier) {
					continue
				}

				// and if base_framework_identifier is set, skip if it isn't to that framework
				if (!empty(this.base_framework_identifier) && alignment.framework_identifier != this.base_framework_identifier) {
					continue
				}

				// load this alignment's framework, and that framework's vectors, if we haven't already
				try {
					let afr = await U.load_framework_and_cfo(alignment.framework_identifier, {load_vectors: true, show_loader: true})
					// store the aligned cfitem and its vector in alignment, for ease of using it later
					this.$store.commit('set', [alignment, 'cfitem', afr.cfo.cfitems[alignment.item_identifier]])
					if (afr.sparkl_bot_vectors) this.$store.commit('set', [alignment, 'cfitem_vector', afr.sparkl_bot_vectors[alignment.item_identifier]])

				} catch(e) {
					console.error('could not load framework for alignment to consider: ' + alignment.framework_identifier)
					continue
				}

				// for SME assocs, try to load crosswalk framework between this framework and the tba framework (it might not exist, which is OK)
				let cfr = await this.load_crosswalk_framework(alignment.framework_identifier, this.tba_framework_identifier)
				// if we have a crosswalk framework, find list of SME assocs for this alignment's item (otherwise we'll have to go through the CFAssociations for every considered node)
				if (cfr) {
					// Note: currently we're only considering dedicated crosswalk frameworks; this means we're skipping assocs that are coded directly in one or the other of the two frameworks being considered

					// first create sme_assocs array on alignment if necessary, then go through each assoc
					if (empty(alignment.sme_assocs)) this.$store.commit('set', [alignment, 'sme_assocs', []])
					for (let assoc of cfr.json.CFAssociations) {
						// if this assoc goes with this alignment's item, add it (but make sure we don't add it more than once)
						if (assoc.destinationNodeURI.identifier == alignment.item_identifier || assoc.originNodeURI.identifier == alignment.item_identifier) {
							if (alignment.sme_assocs.find(x=>
								x.associationType == assoc.associationType &&
								x.destinationNodeURI.identifier == assoc.destinationNodeURI.identifier &&
								x.originNodeURI.identifier == assoc.originNodeURI.identifier
							)) continue
							alignment.sme_assocs.push(assoc)
						}
					}
					
				}

				// if we get to here we'll consider the alignment:
				// since resource is aligned to item IB from framework B, 
				//     then for each item IA from framework TBA, calculate cosim between IA and IB
				//         if cosim is high, it's likely that the resource should be aligned to IA

				arr.push(alignment)
			}
			return arr
		},

		// this is the recursive function for processing nodes of the tba framework to determine which ones are candidates for alignment
		async process_node_for_alignment(node, resource, level) {
			let candidate = null
			// don't process this node if it's not one of the alignable item_types
			if (this.limiters.item_types.includes(U.item_type_string(node.cfitem))) {
				candidate = new Resource_Alignment_Candidate({
					framework_identifier: this.tba_framework_identifier,
					cfitem: node.cfitem,
				})

				// if using education, and this resource has educationLevel specified...
				if (this.comp_factors.education_level && resource.educationLevel?.length > 0) {
					// resource has educationLevel(s)...
					if (node.cfitem.educationLevel?.length > 0) {
						candidate.comps.education_level = U.educationLevel_distance(resource.educationLevel, node.cfitem.educationLevel)
					}

					// if node didn't have an educationLevel (in which case education_level will be -1), or if the diff is > ed_level_tolerance, don't process this candidate
					if (candidate.comps.education_level == -1 || candidate.comps.education_level > this.ed_level_tolerance) {
						// console.log('skipping candidate because education_level = ' + candidate.comps.education_level)
						candidate = null
					}
				}
			}

			if (candidate) {
				// if we're considering the resource metadata, calculate match for them
				if (this.comp_factor_item) {
					this.calculate_resource_metadata_match(candidate, resource, node)
				}

				// if we're considering assocs with existing alignments, calculate match for them
				if (this.comp_factor_assocs) {
					this.calculate_cross_alignments_match(candidate, resource, node)
				}

				// console.warn(`candidate: ${candidate.cfitem.humanCodingScheme} - item: ${candidate.comps.item} / assocs: ${candidate.comps.assocs}`)

				resource.candidates.push(candidate)
			}

			// regardless of whether or not we processed this node, process the node's children
			for (let child of node.children) {
				++this.processed_node_count
				this.process_node_for_alignment(child, resource, level + 1)
			}
		},

		// this is for calculating the match score based on resource metadata between a candidate and a particular cfitem (node)
		calculate_resource_metadata_match(candidate, resource, node) {
			// if we have any metadata to compare, comp_strings will be non-empty; if comp_strings IS empty, nothing to do here
			if (empty(resource.comp_strings)) return

			// for now we just compare to the node; in the future we might also compare to items around it

			// get this node's item's SB vector
			let item_vector = this.tba_framework_record.sparkl_bot_vectors[node.cfitem.identifier]

			// use the highest simscore...
			if (false) {
				let simscore = -1
				if (this.comp_factors.title && resource.resource_title) {
					let ss = U.cosine_similarity(item_vector, resource.comp_string_vectors[0])
					if (ss > simscore) simscore = ss
				}
				if (this.comp_factors.description && resource.description) {
					let ss = U.cosine_similarity(item_vector, resource.comp_string_vectors[1])
					if (ss > simscore) simscore = ss
				}
				if (this.comp_factors.text && resource.text) {
					let ss = U.cosine_similarity(item_vector, resource.comp_string_vectors[2])
					if (ss > simscore) simscore = ss
				}
				if (simscore > -1) candidate.comps.item = (simscore < 0) ? 0 : Math.round(simscore * 1000)
			
			// or use a weighted average of simscores
			} else {
				let numerator = 0
				let denominator = 0
				if (this.comp_factors.title && resource.resource_title) {
					let weight = 1
					denominator += weight
					numerator += U.cosine_similarity(item_vector, resource.comp_string_vectors[0]) * weight
				}
				if (this.comp_factors.description && resource.description) {
					let weight = 1
					denominator += weight
					numerator += U.cosine_similarity(item_vector, resource.comp_string_vectors[1]) * weight
				}
				if (this.comp_factors.text && resource.text) {
					let weight = 1
					denominator += weight
					numerator += U.cosine_similarity(item_vector, resource.comp_string_vectors[2]) * weight
				}
				if (denominator > 0) candidate.comps.item = (numerator < 0) ? 0 : Math.round((numerator / denominator) * 1000)
			}
		},

		// this is for calculating the match score based on existing alignments between a candidate and a particular cfitem (node)
		calculate_cross_alignments_match(candidate, resource, node) {
			// for now at least, use the highest simscore calculated from any existing cross-alignment
			// (we could shift to use the average or some other method...)

			let highest_simscore = -1
			// let n_cosims = 0, n_old_cosims = 0

			// go through every alignment identified to use for matching
			for (let alignment of resource.alignments_for_matching) {
				let sme_simscore = -1, ai_simscore = -1
				
				// look for sme associations between this node's cfitem and the aligned cfitem
				if (this.comp_factors.sme_assocs) {
					if (alignment.sme_assocs) for (let assoc of alignment.sme_assocs) {
						if (assoc.destinationNodeURI.identifier == node.cfitem.identifier || assoc.originNodeURI.identifier == node.cfitem.identifier) {
							// console.warn('found assoc: ' + assoc.associationType)
							let ss		// TODO: make these values configurable?
							if (assoc.associationType == 'exactMatchOf') ss = 1000
							else if (assoc.associationType == 'ext:isNearExactMatch') ss = 950
							else if (assoc.associationType == 'ext:isCloselyRelatedTo') ss = 850
							else if (assoc.associationType == 'ext:isModeratelyRelatedTo') ss = 700
							else if (assoc.associationType == 'isRelatedTo') ss = 700
							else ss = 350
							if (ss > sme_simscore) sme_simscore = ss
						}
					}
				}

				// calculate the ai association if we're using them
				if (this.comp_factors.ai_assocs) {
					// If we've already calculated this ai_assoc, grab it
					let key = `${node.cfitem.identifier}=${alignment.item_identifier}`
					if (!empty(this.calculated_ai_simscores[key])) {
						// console.log('using pre-calculated simscore')
						ai_simscore = this.calculated_ai_simscores[key]
						// ++n_old_cosims

					} else {
						// get this node's item's SB vector
						let node_vector = this.tba_framework_record.sparkl_bot_vectors[node.cfitem.identifier]

						// get the SB vector for the alignment's cfitem
						if (empty(alignment.cfitem_vector) || empty(node_vector)) {
							console.warn('couldn’t get aligned cfitem_vector or node_vector')	// shouldn't happen
						} else {
							// ++n_cosims
							ai_simscore = U.cosine_similarity(node_vector, alignment.cfitem_vector) * 1000
							if (ai_simscore < 0) ai_simscore = 0
							// if items both have ed levels, scale ss according to ed_level_diff
							if (alignment.cfitem.educationLevel?.length > 0 && node.cfitem.educationLevel?.length > 0) {
								let eld = U.educationLevel_distance(alignment.cfitem.educationLevel, node.cfitem.educationLevel)
								// this is a similar algorithm that we use for scaling overall simscores by educationLevel below
								if (eld > 0) {
									ai_simscore = Math.pow(ai_simscore, 1/`1.${eld}`)
								}
							}

							// if both sides have humanCodingSchemes that are exactly the same, and...
							let left_hcs = node.cfitem.humanCodingScheme
							let ca_hcs = alignment.cfitem.humanCodingScheme
							if (!empty(left_hcs) && !empty(ca_hcs) && left_hcs == ca_hcs) {
								// ... the code has at least one letter, one number, and one decimal or dash...
								if (left_hcs.search(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[.-]).+$/) > -1) {
									// then boost the cosim as follows
									ai_simscore = Math.round((2000 + ai_simscore) / 3)
								}
							}

							// store the ai_simscore in this.calculated_ai_simscores to avoid re-calculating later if we come across the same cross-alignment for another resource
							this.calculated_ai_simscores[key] = ai_simscore
						}
					}
				}

				// now get the overall simscore to use for this alignment:
				// if we're only using one or the other of sme/ai, do that
				let alignment_simscore
				if (ai_simscore > -1 && sme_simscore == -1) alignment_simscore = ai_simscore
				else if (ai_simscore == -1 && sme_simscore > -1) alignment_simscore = sme_simscore
				// if we have both... for now we'll just use the sme_simscore
				else alignment_simscore = sme_simscore

				// if this alignment's simscore is > highest_simscore, set highest_simscore
				if (alignment_simscore > highest_simscore) highest_simscore = alignment_simscore
			}

			candidate.comps.assocs = Math.round(highest_simscore)
			
			// console.log(`simscores for ${resource.resource_title}: new ${n_cosims} / old ${n_old_cosims}`)
			// if (sme_simscore > -1) candidate.comps.sme_assocs = Math.round(sme_simscore)
			// if (ai_simscore > -1) candidate.comps.ai_assocs = Math.round(ai_simscore)
		},

		calculate_candidate_params_string(resource) {
			return this.tba_framework_identifier
				 + [resource.resource_title,resource.description,resource.text,resource.educationLevel.join(',')].join('XXX')
				 + this.suggestion_factors_string
		},

		// this returns true if we need to calculate new suggestions (candidates) for the resource, based on
		// a) the currently-tba framework
		// b) the resource's title/description/text, and 
		// c) the currently-selected suggestion factors and limiters
		resource_suggestion_dirty(resource) {
			// if we haven't stored resource.candidate_params_string, we surely need to generate new candidates
			if (empty(resource.candidate_params_string)) return true

			// otherwise check the saved candidate_params_string against what the resource's current candidate_params_string would be
			let s = this.calculate_candidate_params_string(resource)
			return (resource.candidate_params_string != s)
		},

		// algorithm for determining how many candidates to show
		set_candidates_showing(resource, n_to_add) {
			if (resource.candidates.length == 0) {
				this.$store.commit('set', [resource, 'candidates_showing', 0])
				return
			}

			// set target_min, based on current value or min_n, plus n_to_add
			let target_min = resource.candidates_showing || this.candidates_to_show_params.min_n
			target_min += n_to_add

			// target_max is the same as target_min if n_to_add is > 0
			let target_max
			if (n_to_add > 0) target_max = target_min
			else target_max = this.candidates_to_show_params.max_n// otherwise use this

			// now go through each candidate...
			let candidates_showing = 0, max_target_simscore = 0, straggler_threshold = 8
			let debug = ''
			for (let candidate of resource.candidates) {
				if (candidates_showing >= target_max) {
					// if we've reached target_max, break UNLESS this candidate's simscore is very close to max_target_simscore
					if ((max_target_simscore - candidate.simscore) <= straggler_threshold) {
						++candidates_showing
						debug += ` - C(${(max_target_simscore - candidate.simscore)} <= ${straggler_threshold})`
						// and reduce straggler_threshold each time we do this
						if (straggler_threshold > 1) straggler_threshold /= 2

						continue
					}
					debug += ` - D(${(max_target_simscore - candidate.simscore)} > ${straggler_threshold})`
					break
				}

				if (candidates_showing < target_min) { 
					++candidates_showing
					debug += ' - A'
				} else if (candidate.simscore > this.candidates_to_show_params.threshold) {
					++candidates_showing
					debug += ' - B'
				}
				
				max_target_simscore = candidate.simscore
			}
			this.$store.commit('set', [resource, 'candidates_showing', candidates_showing])
			// console.warn('set_candidates_showing: ' + debug)
		},

		make_alignments(resource, aligned_items) {
			return new Promise((resolve, reject)=>{
				// prepare resource data in same form used to import resources; note that we don't have to send any info except resource_id, and note alignments form
				let rdata = {
					resource_id: resource.resource_id,
					alignments: [],
				}

				for (let aligned_item of aligned_items) {
					rdata.alignments.push({
						fi: aligned_item.framework_identifier,
						ii: aligned_item.cfitem.identifier
					})
				}

				let payload = {
					service_url: 'save_resources',
					resources: JSON.stringify([rdata]),
					user_id: this.user_info.user_id,
					return_resources: 'no',
					return_resource_alignments: 'no',
					return_raw_alignments: 'yes',
				}
				if (aligned_items.length < 1) U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					if (aligned_items.length < 1) U.loading_stop()

					// add alignments to the resource, based on new_alignments we get back from the service
					for (let new_alignment of result.new_alignments) {
						this.$store.commit('set', [resource.alignments, 'PUSH', new Resource_Alignment(new_alignment)])

						// also if the resource has candidates and this aligned item is one of them, mark it as currently aligned
						let candidate = resource.candidates.find(x=>x.cfitem.identifier == new_alignment.item_identifier)
						if (candidate) this.$store.commit('set', [candidate, 'currently_aligned', true])
					}

					resolve()

				}).catch((result)=>{
					U.loading_stop()
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
					reject()
				}).finally(()=>{})
			})
		},

		clear_alignments(resource, items_to_clear) {
			return new Promise((resolve, reject)=>{
				// prepare resource data to clear alignment
				let rdata = {
					resource_id: resource.resource_id,
					alignments: []
				}

				// find resource_alignment_ids to clear
				for (let item of items_to_clear) {
					let a = resource.alignments.find(x=>x.framework_identifier == item.framework_identifier && x.item_identifier == item.cfitem.identifier)
					if (a) rdata.alignments.push({clear: a.resource_alignment_id})
				}
				if (rdata.alignments.length == 0) return	// shouldn't happen

				let payload = {
					service_url: 'save_resources',
					resources: JSON.stringify([rdata]),
					user_id: this.user_info.user_id,
					return_resources: 'no',
					return_resource_alignments: 'no',
					return_raw_alignments: 'no',
				}
				if (rdata.alignments.length < 1) U.loading_start()
				this.$store.dispatch('service', payload).then((result)=>{
					if (rdata.alignments.length < 1) U.loading_stop()

					// remove alignments from the resource
					for (let o of rdata.alignments) {
						let i = resource.alignments.findIndex(x=>x.resource_alignment_id == o.clear)
						let alignment = resource.alignments[i]
						if (i > -1) this.$store.commit('set', [resource.alignments, 'SPLICE', i])

						// also if the resource has candidates and this item is one of them, mark it as not currently aligned
						let candidate = resource.candidates.find(x=>x.cfitem.identifier == alignment?.item_identifier)
						if (candidate) this.$store.commit('set', [candidate, 'currently_aligned', false])
					}

					resolve()

				}).catch((result)=>{
					U.loading_stop()
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
					reject()
				}).finally(()=>{})
			})
		},

	}
}