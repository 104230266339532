<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-menu bottom v-model="menu_showing" nudge-right="0" nudge-top="-30">
		<template v-slot:activator="{on}">
			<v-btn v-show="btn_size=='small'" ref="trigger_btn" v-on="on" icon :color="color" :style="bstyle" text><v-icon small>fas fa-arrow-right-arrow-left</v-icon></v-btn>
			<v-btn v-show="btn_size=='large'" v-on="on" :color="color" dark class="k-tight-btn" @click="" v-html="large_btn_content"></v-btn>
		</template>
		<v-list dense class="framework_switcher_menu">
			<v-list-item v-if="btn_size=='small'" class="mt-1 mb-3" stylex="border-bottomx:1px solid #ccc; margin-top:-8px;">
				<!-- <v-list-item-icon><v-icon small color="black">fas fa-arrow-right-arrow-left</v-icon></v-list-item-icon> -->
				<v-list-item-title class="text-center"><v-icon small class="mr-2" color="#444">fas fa-arrow-right-arrow-left</v-icon><b style="color:#444">Switch to framework…</b></v-list-item-title>
			</v-list-item>

			<v-list-item class="pb-2">
				<!-- <v-list-item-icon><v-icon small>fas fa-search</v-icon></v-list-item-icon> -->
				<v-list-item-title>
					<!-- this v-autocomplete serves as a search bar -->
					<v-autocomplete label="" outlined dense hide-details prepend-inner-icon="fas fa-search"
						v-model="autocomplete_framework_identifier" 
						:items="all_frameworks"
						@change="$emit('framework_selected', $event);menu_showing=false"
						@click.stop=""
					></v-autocomplete>
				</v-list-item-title>
			</v-list-item>

			<v-menu v-for="(cr, index) of category_records" :key="index" :close-on-content-click="false" :transition="false" offset-x right :nudge-top="8" :nudge-left="($vuetify.breakpoint.width<700)?0:12" style="display: block;">
				<template v-slot:activator="{on}"><v-hover v-slot:default="{hover}"><v-list-item v-on="on" style="cursor:pointer;" :style="hover?'background-color:#eee':''">
					<v-list-item-icon><v-icon small>fas fa-layer-group</v-icon></v-list-item-icon><v-list-item-title :style="(cr==selected_category||cr.index==-1)?'font-weight:bold; font-style:italic;':''" v-html="cr.title"></v-list-item-title>
					<v-list-item-action class="justify-end"><v-icon small>fas fa-chevron-right</v-icon></v-list-item-action>
				</v-list-item></v-hover></template>

				<v-list dense style="max-height:calc(100vh - 30px); overflow:auto;">
					<v-list-item v-for="(fr) of cr.framework_records" :key="fr.lsdoc_identifier" @click="$emit('framework_selected', fr.lsdoc_identifier);menu_showing=false"><v-list-item-icon><v-icon small>fas fa-map</v-icon></v-list-item-icon><v-list-item-title style="overflow:visible; white-space:normal" :style="fr==selected_framework?'font-weight:bold; font-style:italic;':''" v-html="fr.json.CFDocument.title"></v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		btn_size: { type: String, required: false, default() { return 'large'} },	// small or large
		color: { type: String, required: false, default() { return 'brown darken-3'} },
		bstyle: { type: String, required: false, default() { return ''} },
		large_btn_content: { type: String, required: false, default() { return 'BUTTON'} },
		current_selected_framework_identifier: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		menu_showing: false,
		category_records: [],
		selected_category: null,
		autocomplete_framework_identifier: this.current_selected_framework_identifier,
	}},
	computed: {
		...mapState(['framework_records', 'framework_categories', 'embedded_mode']),
		...mapGetters([]),
		framework_noun() { return this.$store.state.site_config.framework_noun },
		selected_framework() { 
			return this.framework_records.find(x=>x.lsdoc_identifier == this.current_selected_framework_identifier) 
		},
		all_frameworks() {
			let arr = []
			for (let fr of this.$store.getters.filtered_framework_records) {
				let doc = fr.json.CFDocument
				// skip crosswalk frameworks and sandboxes
				if (doc.frameworkType == 'crosswalk') continue
				if (!empty(fr.ss_framework_data.sandboxOfIdentifier)) continue
				arr.push({value: doc.identifier, text: doc.title})
			}
			// sort by title
			arr.sort((a,b)=>U.natural_sort(a.text, b.text))
			return arr
		},
	},
	watch: {
		current_selected_framework_identifier() {
			// when framework switches, re-create category_records so that the menus will update to reflect the current framework
			this.create_category_records()
		},
	},
	created() {
	},
	mounted() {
		// note that this component should not be included anywhere until the document list has been loaded (get_lsdoc_list, which is called immediately upon initializing)
		this.create_category_records()
	},
	methods: {
		create_category_records() {
			// this fn is in case_utilities, because we also need it for FrameworkList
			this.category_records = U.create_category_records(false)

			// if a framework is already selected,
			if (this.current_selected_framework_identifier) {
				// look for that framework in the category_records
				let cat_index = this.category_records.findIndex(x=>x.framework_records.find(y=>y.lsdoc_identifier == this.current_selected_framework_identifier))
				// if found, store it in this.selected_category and bump that category to the top of the list
				if (cat_index) {
					this.selected_category = this.category_records.splice(cat_index, 1)[0]
					this.category_records.unshift(this.selected_category)
				}
			}

			// add recent category to start of list, if we have any recents
			let cr = U.recent_framework_category(false)
			if (cr.framework_records.length > 0) {
				this.category_records.unshift(cr)
			}
		},
	}
}
</script>

<style lang="scss">
// .framework_switcher_menu {
// 	max-height:calc(100vh - 30px); 
// 	// width:400px;
// 	overflow:auto; 
// 	padding-bottom:0;

// 	.v-list-item {
// 		min-height:auto!important;
// 	}
// }

</style>
