<template><div class="k-resource-view" style="position:relative">
	<div style="position:absolute; left:12px; top:16px;"><v-icon class="k-resource-view-resource-icon" style="font-size:28px" :style="resource_icon_style" :color="resource_color">fas fa-{{resource_icon}}</v-icon></div>

	<!--------------------------- EDITOR -------------------------------->
	<div v-if="editing_resource&&current_resource">
		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label"></div>
			<h3 v-if="is_new_resource"><v-icon color="black" class="mr-2">fas fa-circle-plus</v-icon>Add Resource</h3>
			<h3 v-if="!is_new_resource"><v-icon color="black" class="mr-2">fas fa-edit</v-icon>Edit Resource</h3>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label" @click="generate_random_resource_id">*Resource ID:</div>
			<v-text-field background-color="#fff" outlined dense hide-details v-model="resource_for_editing.external_resource_id" placeholder="" clearable></v-text-field>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label">Title:</div>
			<v-text-field background-color="#fff" outlined dense hide-details v-model="resource_for_editing.resource_title" placeholder="" clearable></v-text-field>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label">URL:</div>
			<v-text-field background-color="#fff" outlined dense hide-details v-model="resource_for_editing.url" placeholder="" clearable></v-text-field>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label">Description:</div>
			<v-textarea background-color="#fff" outlined dense hide-details v-model="resource_for_editing.description" placeholder="" rows="2" auto-grow clearable></v-textarea>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label">Keywords:</div>
			<v-textarea background-color="#fff" outlined dense hide-details v-model="resource_for_editing.text" placeholder="" rows="2" auto-grow clearable></v-textarea>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label">Education&nbsp; level:</div>
			<div class="d-flex" style="width:200px">
				<div style="flex:1 1 50%"><v-select background-color="#fff" v-model="grade_low" :items="grades" label="Low" outlined dense hide-details :menu-props="{top:true,dense:true}"></v-select></div>
				<div class="ml-1" style="flex:1 1 50%"><v-select background-color="#fff" v-model="grade_high" :items="grades" label="High" outlined dense hide-details :menu-props="{top:true}"></v-select></div>
			</div>
			<v-spacer/>
			<v-btn small color="red darken-3" dark class="mr-6" @click="edit_resource_remove"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Remove</v-btn>
			<v-btn small color="secondary" class="mr-2" @click="edit_resource_cancel"><v-icon small class="mr-2">fas fa-xmark</v-icon> Cancel</v-btn>
			<v-btn small :color="!edited_resource_changed?'#ccc':resource_color" dark @click="edit_resource_save"><v-icon small class="mr-2">fas fa-save</v-icon>Save {{is_new_resource?'Resource':'Changes'}}</v-btn>
		</div>
	</div>

	<!--------------------------- VIEWER -------------------------------->
	<div v-if="!editing_resource&&current_resource" class="k-resource-alignment-maker-resource-fields">
		<div class="k-case-ie-line" style="position:relative">
			<div class="k-case-ie-line-value" style="font-size:13px; margin-left:36px;">
				<b style="color:#444">Resource ID:</b><span style="margin-left:12px" v-html="current_resource.external_resource_id"></span>
				<v-btn small icon color="#444" style="margin:-4px 0 0 4px;" @click="edit_resource_start"><v-icon small>fas fa-edit</v-icon></v-btn>
			</div>
			<v-spacer/>
			<div class="d-flex align-center" style="margin-top:-4px">
				<v-btn small fab :color="resource_color" outlined class="" @click="$emit('go_to_resource','prev')" :disabled="current_resource_index<1"><v-icon small>fas fa-arrow-left</v-icon></v-btn>
				<v-btn small class="ml-1" fab :color="resource_color" outlined @click="$emit('go_to_resource','next')" :disabled="current_resource_index>=(table_filtered_resources.length-1)"><v-icon small>fas fa-arrow-right</v-icon></v-btn>
				<v-btn class="ml-3 elevation-0" small fab :color="resource_color" dark @click="$emit('show_table')"><v-icon>fas fa-table</v-icon></v-btn>
			</div>
		</div>

		<div class="k-case-ie-line" v-if="current_resource.url">
			<div class="k-case-ie-line-label d-flex align-center">
				<v-checkbox v-visible="false" class="shrink mt-0 pt-0 float-left" hide-details></v-checkbox> <!-- placeholdr -->
				<div>URL:</div>
			</div>
			<div class="k-case-ie-line-value" style="white-space:nowrap; overflow:hidden;"><a :href="current_resource.url" target="_blank" v-html="current_resource.url"></a></div>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label d-flex align-center">
				<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.title" @change="comp_factors_changed"></v-checkbox>
				<div>Title:</div>
			</div>
			<div class="k-case-ie-line-value" v-html="current_resource.resource_title"></div>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label d-flex align-center">
				<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.description" @change="comp_factors_changed"></v-checkbox>
				<div>Description:</div>
			</div>
			<div class="k-case-ie-line-value" v-html="current_resource.description"></div>
		</div>

		<div class="k-case-ie-line">
			<div class="k-case-ie-line-label d-flex align-center">
				<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.text" @change="comp_factors_changed"></v-checkbox>
				<div>Keywords:</div>
			</div>
			<!-- We always show the extra text editor; current_resource_text_wrapper auto-saves edits -->
			<v-textarea background-color="#fff" outlined dense hide-details v-model="current_resource_text_wrapper" placeholder="" rows="1" auto-grow clearable @keydown="current_resource_text_keydown"></v-textarea>
			<!-- <div class="k-case-ie-line-value" v-html="current_resource.text"></div> -->
		</div>

		<div class="k-case-ie-line" v-if="grade_low">
			<div class="k-case-ie-line-label d-flex align-center">
				<v-checkbox class="shrink mt-0 pt-0 float-left" hide-details v-model="comp_factors.education_level" @change="comp_factors_changed"></v-checkbox>
				<div>Ed. level:</div>
			</div>
			<div class="k-case-ie-line-value">{{grade_low.text}}<span v-if="grade_low!=grade_high">–{{grade_high.text}}</span></div>
		</div>

		<div class="k-case-ie-line">
		</div>

		<!-- candidate suggestions -->
		<div v-if="tba_framework_identifier" class="mt-4 pt-0" :style="`border-top:1px solid ${resource_color}`">
			<div class="d-flex align-start mt-2">
				<nobr><b>Alignment Suggestions:</b></nobr>

				<div v-if="suggestion_computing" class="k-suggestion-computing-flasher ml-2" style="border-radius:4px; padding:1px 5px; margin-top:3px; font-size:12px; font-weight:bold;">COMPUTING…</div>
				<div v-if="suggestions_updated_showing" class="green darken-3 white--text ml-2" style="border-radius:4px; padding:1px 5px; margin-top:3px; font-size:12px; font-weight:bold;">UPDATED</div>

				<v-spacer/>
				<v-btn x-small color="#777" text style="margin-top:3px" @click="item_suggestions_wrap=!item_suggestions_wrap"><v-icon small style="transform:rotate(90deg);">fas fa-turn-down</v-icon></v-btn>
			</div>
			<div class="my-2 grey--text text--darken-2" style="font-size:14px; line-height:18px; margin-top:4px; font-weight:bold;" v-html="tba_framework_record_descriptor"></div>

			<div v-if="!comp_factors_available" class="mt-2" style="font-size:14px; line-height:18px"><i>You have not selected any factors for making suggestions. Try selecting and entering “Keywords”.</i></div>
			
			<div v-if="comp_factors_available && !suggestion_computing">
				<div v-if="base_item_for_alignment" class="mb-0 pink--text text--darken-3">
					<div>Limiting to items closest to item from Base Framework:<v-btn class="ml-2" x-small color="pink darken-3" dark @click="base_item_for_alignment=null;make_suggestions()">Cancel</v-btn></div>
					<div class="d-flex mt-1 ml-8 align-start" style="font-size:14px; line-height:18px"><b>{{base_item_for_alignment.ao.hcs}}</b><div class="ml-2" v-html="base_item_for_alignment.ao.statement"></div></div>
				</div>
				<div v-for="(candidate, i) in current_resource.candidates" v-if="candidate.simscore > 0 && (candidate.currently_aligned || i<current_resource.candidates_showing)" class="k-resource-view-current-alignment" :class="candidate_outer_class(candidate)">
					<v-checkbox class="shrink pt-0 float-left" color="green darken-3" style="margin-top:-3px" hide-details v-model="candidate.currently_aligned" @change="candidate_alignment_changed(candidate)"></v-checkbox>
					<div class="mr-2"><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="d-flex">
						<div style="font-size:13px; margin:-1px 6px 0 0; width:24px; text-align:right;">{{candidate.simscore}}</div>
						<div style="width:100px"><v-progress-linear v-model="candidate.simscore_pct" :color="candidate_color(candidate)" height="16"></v-progress-linear></div>
					</div></template><div class="k-resource-factor-tooltip-outer" v-html="candidate.factor_string"></div></v-tooltip></div>
					<b v-if="candidate.cfitem.humanCodingScheme" class="k-resource-view-item-link mr-2" style="white-space:nowrap" v-html="candidate.cfitem.humanCodingScheme" @click="candidate_clicked(candidate)"></b>
					<div class="k-resource-view-item-link" v-html="candidate.cfitem.fullStatement" @click="candidate_clicked(candidate)"></div>
				</div>
				<v-btn v-if="current_resource.candidates.length > current_resource.candidates_showing" small text class="mt-2 ml-7" :color="resource_color" @click="set_candidates_showing(current_resource, 5)"><v-icon small class="mr-2">fas fa-plus</v-icon> More Suggestions</v-btn>
			</div>
		</div>

		<!-- Current alignments -->
		<div v-if="!suggestion_computing" class="mt-3 pt-2" :style="`border-top:2px solid ${resource_color}`">
			<div class="d-flex align-center">
				<b style="color:#444;">Current Alignments:</b>
				<v-spacer/>
				<v-checkbox class="shrink mt-0 pt-0 ml-4" hide-details v-model="comp_factors.sme_assocs" @change="comp_factors_changed"><template v-slot:label><div style="font-size:13px; margin-left:-2px">SME-Based</div></template></v-checkbox>
				<v-checkbox class="shrink mt-0 pt-0 ml-4 mr-2" hide-details v-model="comp_factors.ai_assocs" @change="comp_factors_changed"><template v-slot:label><div style="font-size:13px; margin-left:-2px">AI-Based</div></template></v-checkbox>
				<v-btn x-small color="#777" text @click="item_alignments_wrap=!item_alignments_wrap"><v-icon small style="transform:rotate(90deg);">fas fa-turn-down</v-icon></v-btn>
			</div>
			<div v-if="no_alignments_to_base_framework" class="mt-2"><b>NO ALIGNMENTS TO BASE FRAMEWORK</b> ({{ base_framework_record.json.CFDocument.title }})</div>
			<div v-if="alignments_to_show.length==0"><i>No alignments</i></div>
			<div v-else>
				<div v-for="(framework) in alignments_to_show" :key="framework.cfdocument.identifier" class="mt-2 pt-1 ml-4" :style="`border-top:1px solid #aaa`">
					<div class="d-flex mt-1">
						<b v-if="framework.cfdocument.identifier==base_framework_identifier" style="font-size:14px; line-height:18px;" class="mr-2">BASE FRAMEWORK:</b>
						<b class="grey--text text--darken-2" style="font-size:14px; line-height:18px;">{{framework.cfdocument.title}}</b>
						<v-spacer/>
						<div class="ml-3" style="font-size:14px;"><nobr>{{ framework.alignments.length }} Alignments</nobr></div>
					</div>
					<div v-if="!base_framework_identifier || framework.cfdocument.identifier==base_framework_identifier">
						<div v-for="(ao) in framework.alignments" :key="ao.key" class="k-resource-view-current-alignment" :class="item_alignments_wrap?'':'k-resource-view-current-alignment-nowrap'">
							<v-btn x-small icon dark style="width:24px;height:24px;margin-top:-4px;" class="mr-1 elevation-0" :color="resource_color" @click="show_alignment(ao.alignment)"><v-icon x-small style="transform:rotate(45deg)">fas fa-down-left-and-up-right-to-center</v-icon></v-btn>
							<b v-if="ao.hcs" class="mr-2 k-resource-view-item-link" style="white-space:nowrap" v-html="ao.hcs" @click="show_alignment(ao.alignment)"></b>
							<div class="k-resource-view-item-link" v-html="ao.statement" @click="show_alignment(ao.alignment)"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import ResourceAlignmentMixin from './ResourceAlignmentMixin'

export default {
	components: {  },
	// mixins: [ResourceAlignmentMixin],
	props: {
		set_component: { required: true },
		resource_set: { type: Object, required: true },
		current_resource: { required: true },
		table_filtered_resources: { required: true },
		batch_alignments_to_create: { required: true },
		satchel_embed_width: { required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		resource_for_editing: null,
		current_resource_text: '',

		mode: '',
		lowest_ancestor: '',
		resource_icon: 'cube',
		resource_icon_rotation: 0,

		no_alignments_to_base_framework: false,

		save_current_resource_text_debounced: null,
		prevent_current_resource_text_debounce: false,

		candidates_showing_on_right: [],
	}},
	computed: {
		...mapState(['framework_records', 'association_type_labels', 'grades', 'user_info']),
		...mapGetters([]),
		resource_set_id() { return this.resource_set.resource_set_id },
		can_edit_alignments() {
			// for now we'll assume that if you're here you can edit alignments
			return true
		},
		resources() { return this.resource_set?.resources },
		resource_id() { return this.current_resource?.resource_id },
		base_item_for_alignment: {
			get() { return this.$store.state.base_item_for_alignment[this.resource_id] },
			set(val) { this.$store.commit('set', ['base_item_for_alignment', this.resource_id, val]) }
		},
		is_new_resource() { return this.resource_id == 1 },
		current_resource_index() { 
			if (!this.resources) return null
			return this.table_filtered_resources.findIndex(x=>x.resource_id == this.resource_id)
		},
		resource_color() {
			if (this.resource_for_editing) return this.resource_for_editing?.random_color()
			return this.current_resource?.random_color()
		},
		resource_icon_style() { 
			// return ''
			if (this.resource_icon_rotation == 0) return ''
			if (this.resource_for_editing) return `transform:rotate(${this.resource_icon_rotation}deg)`
			return `transform:rotate(${this.resource_icon_rotation}deg)`
		},
		item_alignments_wrap: {
			get() { return this.$store.state.lst.resource_set_item_alignments_wrap },
			set(val) { this.$store.commit('lst_set', ['resource_set_item_alignments_wrap', val]) }
		},
		item_suggestions_wrap: {
			get() { return this.$store.state.lst.resource_set_item_suggestions_wrap },
			set(val) { this.$store.commit('lst_set', ['resource_set_item_suggestions_wrap', val]) }
		},
		editing_resource() { return !empty(this.resource_for_editing) },
		edited_resource_changed() {
			if (!this.editing_resource) return false
			return JSON.stringify(this.current_resource.copy_for_save()) != JSON.stringify(this.resource_for_editing.copy_for_save())
		},
		grade_low: {
			get() {
				let resource = (this.editing_resource) ? this.resource_for_editing : this.current_resource
				if (!resource || resource.educationLevel.length == 0) return ''
				let el = resource.educationLevel[0]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new lower-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current upper-limit education level
				let el_high = this.resource_for_editing.educationLevel[this.resource_for_editing.educationLevel.length - 1]
				let el_high_index = this.grades.findIndex(g => { return (g.value == el_high || ( !isNaN(el_high*1) && (g.value*1 == el_high*1) )) })

				// if el_high_index is < new_el_index,
				if (el_high_index < new_el_index) {
					// if 9th grade was chosen, default to 12, because most HS courses are 9-12
					if (new_el == '09') el_high_index = this.grades.findIndex(g=>g.value=='12')
					// else just include new_el_index
					else el_high_index = new_el_index
				}

				// include all grades between new_el and el_high
				let arr = []
				for (let i = new_el_index; i <= el_high_index; ++i) {
					if (!empty(this.grades[i].value)) arr.push(this.grades[i].value)
				}
				this.resource_for_editing.educationLevel = arr
			}
		},
		grade_high: {
			get() {
				let resource = (this.editing_resource) ? this.resource_for_editing : this.current_resource
				if (!resource || resource.educationLevel.length == 0) return ''
				let el = resource.educationLevel[resource.educationLevel.length-1]
				let grade = this.grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
				if (!empty(grade)) return grade
				return ''
			},
			set(new_el) {
				// get grade index of new upper-limit education level
				let new_el_index = this.grades.findIndex(g => { return (g.value == new_el || ( !isNaN(new_el*1) && (g.value*1 == new_el*1) )) })

				// get grade index of current lower-limit education level
				let el_low = this.resource_for_editing.educationLevel[0]
				let el_low_index = this.grades.findIndex(g => { return (g.value == el_low || ( !isNaN(el_low*1) && (g.value*1 == el_low*1) )) })

				// if el_low_index is > new_el_index, just include new_el_index
				if (el_low_index > new_el_index) el_low_index = new_el_index

				// include all grades between new_el and el_high
				let arr = []
				for (let i = el_low_index; i <= new_el_index; ++i) {
					if (!empty(this.grades[i].value)) arr.push(this.grades[i].value)
				}
				this.resource_for_editing.educationLevel = arr
			}
		},
		current_resource_text_wrapper: {
			get() { return this.current_resource_text },
			set(val) {
				this.current_resource_text = val
				// establish the debounce fn if necessary
				if (empty(this.save_current_resource_text_debounced)) {
					this.save_current_resource_text_debounced = U.debounce(() => {
						// console.log('debouncer: ' + this.current_resource_text)
						this.$store.commit('set', [this.current_resource, 'text', this.current_resource_text])
						this.edit_resource_save('auto_save')

						// if the user tapped the enter key, clear the flag
						if (this.prevent_current_resource_text_debounce) {
							this.prevent_current_resource_text_debounce = false

						// otherwise if have a tba_framework_identifier, update suggestions
						} else if (this.tba_framework_identifier) {
							this.make_suggestions()
						}
					}, 1000)
				}
				// call the debounce fn
				this.save_current_resource_text_debounced()
			}
		},

		alignments_to_show() {
			let add_fr = (framework_identifier) => {
				let framework_record = this.framework_records.find(x=>x.lsdoc_identifier == framework_identifier)
				if (!framework_record) {	// shouldn't happen
					console.error('Couldn’t find framework_record for framework_identifier ' + framework_identifier)
					return false
				}
				let f = {
					framework_record: framework_record,
					cfdocument: framework_record.json.CFDocument,
					alignments: []
				}
				// if framework isn't fully loaded, load it now
				if (!framework_record.framework_json_loaded && !framework_record.framework_json_loading) {
					// but if we're using a base framework, don't take the time to load here
					if (empty(this.base_framework_identifier)) {
						U.load_framework_and_cfo(framework_record.lsdoc_identifier)
					}
				}
				return f
			}

			let arr = []

			// side effect: determine no_alignments_to_base_framework. for starters assume this is TRUE if we HAVE a base framework
			this.no_alignments_to_base_framework = !empty(this.base_framework_identifier)

			for (let a of this.current_resource.alignments) {
				let f = arr.find(x=>x.cfdocument.identifier == a.framework_identifier)
				if (!f) {
					f = add_fr(a.framework_identifier)
					if (a.framework_identifier == this.tba_framework_identifier) {
						// if we're currently aligning, make sure that framework appears first
						arr.unshift(f)
					} else {
						arr.push(f)
					}
				}

				// PW: at some point I had this here, but if we do this we don't list the # of alignments for each framework when we have a base framework
				// if (empty(f.framework_record.cfo)) {
				// 	continue
				// }

				// if we find an alignment to the base framework, no_alignments_to_base_framework is FALSE
				if (a.framework_identifier == this.base_framework_identifier) {
					this.no_alignments_to_base_framework = false
				}

				let o = {}
				o.alignment = a
				o.key = `${a.framework_identifier}-${a.item_identifier}`

				// skip duplicates
				if (f.alignments.find(x=>x.key == o.key)) { console.log('skipping duplicate alignment'); continue; }
				
				// set default statement and tree_key, then try to get actual statement and tree_key from framework_record if it's loaded
				// (note that if it's not loaded initially, it should be loaded momentarily)
				o.statement = `${a.framework_identifier} / ${a.item_identifier}`
				o.tree_key = 1000000
				if (f.framework_record.cfo && f.framework_record.cfo.cfitems) {
					let cfitem = f.framework_record.cfo?.cfitems[a.item_identifier]
					if (cfitem) {
						o.hcs = cfitem.humanCodingScheme
						o.statement = U.marked_latex(cfitem.fullStatement)
						// if (cfitem.humanCodingScheme) o.statement += `<b class="mr-2">${cfitem.humanCodingScheme}</b> `
						// o.statement += 
						o.tree_key = cfitem.tree_nodes[0]?.tree_key ?? 1000000
					}
				}
				f.alignments.push(o)
			}

			// sort each framework's alignments by tree_key
			for (let o of arr) {
				o.alignments.sort((a,b) => {
					return a.tree_key - b.tree_key
				})
			}

			// sort so that the base framework is on top
			if (!empty(this.base_framework_identifier)) {
				arr.sort((a,b)=>{
					if (a.framework_record.lsdoc_identifier == this.base_framework_identifier) return -1
					if (b.framework_record.lsdoc_identifier == this.base_framework_identifier) return 1
					return 0
				})
			}
			return arr
		},

		// return true iff we have comp factors available for this resource
		comp_factors_available() {
			if (this.comp_factors.title && this.current_resource.resource_title) return true
			if (this.comp_factors.description && this.current_resource.description) return true
			if (this.comp_factors.text && this.current_resource.text) return true
			if ((this.comp_factors.sme_assocs || this.comp_factors.ai_assocs) && this.current_resource.alignments.length > 0) return true
			return false
		},

		suggestions_updated_showing() { return this.set_component.suggestions_updated_showing },
		suggestion_computing() { return this.set_component.suggestion_computing },
		comp_factors() { return this.set_component.comp_factors },
		base_framework_identifier: {
			get() { return this.$store.state.lst.align_base_framework_identifier[this.tba_framework_identifier] ?? '' },
			set(val) { this.$store.commit('lst_set_hash', ['align_base_framework_identifier', this.tba_framework_identifier, val]) }
		},
		base_framework_record() { return this.set_component.base_framework_record },
		suggestion_factors_string() { return this.set_component.suggestion_factors_string },
		auto_starting_alignment: {
			get() { return this.$store.state.lst.resource_set_auto_starting_alignment },
			set(val) { this.$store.commit('lst_set', ['resource_set_auto_starting_alignment', val]) }
		},
		// 'to-be-aligned' framework_identifier
		tba_framework_identifier: {
			get() { return this.$store.state.lst.resource_set_tba_framework_identifier_hash[this.resource_set_id] },
			set(val) { this.$store.commit('lst_set_hash', ['resource_set_tba_framework_identifier_hash', this.resource_set_id, val]) }
		},
		tba_framework_record() { return this.set_component.tba_framework_record },
		tba_framework_record_descriptor() { return this.set_component.tba_framework_record_descriptor },
	},
	watch: {
		current_resource: {immediate: true, handler() {
			this.current_resource_text = this.current_resource.text
			this.candidates_showing_on_right = []
			if (this.resource_id == 1) {
				this.edit_resource_start()
			} else if (this.tba_framework_identifier) {
				if (this.resource_suggestion_dirty(this.current_resource)) {
					this.make_suggestions()
				}
				this.auto_start_alignment()
			}
			this.resource_icon = this.current_resource.random_icon()
			// this.resource_icon_rotation = this.current_resource.random_icon_rotation()
			// console.warn('resetting base_item_for_alignment')
			// this.base_item_for_alignment = ''
		}},

		suggestion_factors_string() {
			// console.warn('suggestion_factors_string changed')
			if (this.tba_framework_identifier) {
				this.make_suggestions()
				this.auto_start_alignment()
			}
		},
	},
	created() {
		if (empty(this.current_resource)) {
			this.$emit('show_table', 'confirmed')
			return
		}

		vapp.resource_viewer_component = this
	},
	mounted() {
	},
	methods: {
		report_ms(str) { return this.set_component.report_ms(str) },
		comp_factors_changed(param, val) { return this.set_component.comp_factors_changed(param, val) },
		resource_suggestion_dirty(resource) { return this.set_component.resource_suggestion_dirty(resource) },
		set_candidates_showing(resource, n_to_add) { return this.set_component.set_candidates_showing(resource, n_to_add) },
		make_alignments(resource, aligned_items) { return this.set_component.make_alignments(resource, aligned_items) },
		clear_alignments(resource, items_to_clear) { return this.set_component.clear_alignments(resource, items_to_clear) },

		candidate_color(candidate) {
			if (candidate.currently_aligned) return 'green darken-3'
			let r = this.batch_alignments_to_create.find(x=>x.resource_id==this.resource_id)
			if (r) {
				if (r.alignments.find(x=>x.ii == candidate.cfitem.identifier)) return 'pink darken-3'
			}
			// if (this.batch_alignments_to_create.find(x=>x.resource_id==this.resource_id)) return 'pink darken-3'
			return 'grey darken-4'
		},

		candidate_outer_class(candidate) {
			let s = ''
			// apply nowrap class if needed
			if (!this.item_suggestions_wrap) s += ' k-resource-view-current-alignment-nowrap'

			// and apply class indicating the user has clicked it if needed
			if (this.candidates_showing_on_right.includes(candidate.cfitem.identifier)) {
				s += ' k-resource-view-candidate-clicked'
			}

			return s
		},

		edit_resource_start() {
			// console.warn('hide: edit_resource_start')
			vapp.$refs.satchel.execute('hide')
			this.resource_for_editing = new Resource(this.current_resource)
			this.resource_icon = this.resource_for_editing.random_icon()
			this.resource_icon_rotation = this.resource_for_editing.random_icon_rotation()
		},

		edit_resource_cancel(flag) {
			if (flag !== 'confirmed' && this.edited_resource_changed) {
				// stash original flag in case we re-call this fn
				this.original_edit_resource_cancel_flag = flag

				let msg
				if (this.is_new_resource) msg = `You appear to have started making edits to this new resource record. 	Are you sure you want to cancel and discard your edits?`
				else msg = `You appear to have made edits to this resource record. Are you sure you want to cancel and discard your edits?`
				this.$confirm({
					title: 'Are you sure?',
					text: msg,
					acceptText: 'Discard Edits',
					cancelText: 'Keep Editing',
				}).then(y => {
					if (this.is_new_resource) {
						this.$emit('show_table', 'confirmed')
					} else {
						this.edit_resource_cancel('confirmed')
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// the user clicked in ResourceSet to show the table, or this was a new resource, emit show_table here
			if (this.is_new_resource || this.original_edit_resource_cancel_flag == 'show_table') {
				this.original_edit_resource_cancel_flag = ''
				this.$emit('show_table', 'confirmed')
			}
			this.original_edit_resource_cancel_flag = ''

			this.resource_icon = this.current_resource.random_icon()
			this.resource_icon_rotation = this.current_resource.random_icon_rotation()

			// otherwise cancel editing
			this.resource_for_editing = null
		},

		edit_resource_save(flag) {
			let resource_to_save
			if (flag === 'auto_save') {
				// if we're autosaving, we're just editing the current resource's text, so create a special object with just this data to send to the server
				resource_to_save = {
					resource_id: this.resource_id,
					external_resource_id: this.current_resource.external_resource_id,
					text: this.current_resource.text
				}

			} else {
				// we're in editing mode; if nothing changed, return
				if (!this.edited_resource_changed) return

				// else show the loading indicator, and clear alignments from resource_for_editing before save
				U.loading_start()
				resource_to_save = this.resource_for_editing.copy_for_save()
			}

			let payload = {
				service_url: 'save_resources',
				// we stringify the resources in case it's a lot of data
				resources: JSON.stringify([resource_to_save]),
				user_id: this.user_info.user_id,
				return_resources: 'yes',
				return_resource_alignments: 'no',	// we're not updating alignments here
				return_raw_alignments: 'no',
			}
			this.$store.dispatch('service', payload).then((result)=>{
				// if we're auto-saving the extra text, the computed fn will have already updated the resource's text field
				if (flag === 'auto_save') return

				U.loading_stop()
				let resource_data = result.resources[0]
				this.resource_for_editing = null

				// if we just created and saved a new resource...
				if (this.is_new_resource) {
					this.$emit('new_resource_created', resource_data)
					return
				}

				// else update properties of the edited resource, then stop editing
				for (let key in resource_data) {
					this.$store.commit('set', [this.current_resource, key, resource_data[key]])
				}

				// make sure current_resource_text is set correctly -- we might have just saved it from the editor
				this.current_resource_text = this.current_resource.text

				// set the resource_title_index for the updated resource, then make sure we're sorted properly
				this.set_component.set_resource_title_indices()
				this.set_component.sort_table_filtered_resources()

				if (this.tba_framework_identifier && this.resource_suggestion_dirty(this.current_resource)) {
					this.make_suggestions()
				}

			}).catch((result)=>{
				console.warn(result)
				if (flag === 'auto_save') U.loading_stop()
				this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
			}).finally(()=>{})
		},

		edit_resource_remove() {
			// user must be the creator of either the set or the resource to be able to remove
			if (this.user_info.user_id != this.resource_set.creator_user_id) {
				if (this.user_info.user_id != this.current_resource.creator_user_id) {
					this.$alert('Only the creator of a resource, or the creator of the resource’s resource set, can remove a resource.')
					return
				}
			}

			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to remove this resource from the set?',
				acceptText: 'Remove',
				acceptColor: 'red darken-3',
			}).then(y => {
				let payload = {
					service_url: 'remove_resources',
					resource_ids: [this.current_resource.resource_id],
					user_id: this.user_info.user_id,
				}
				this.$store.dispatch('service', payload).then((result)=>{
					this.$emit('resource_removed', this.current_resource.resource_id)

				}).catch((result)=>{
					this.$alert('<b class="red--text">An error occurred:</b> ' + (result.error ? result.error : result.status))
				}).finally(()=>{})

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		toggle_choose_lowest_ancestor() {
		},

		cancel_lowest_ancestor() {
		},

		current_resource_text_keydown($evt) {
			// if user taps enter key, immediately make suggestions
			if ($evt.key === "Enter") {
				this.current_resource_text = $.trim(this.current_resource_text)
				this.make_suggestions()
				$evt.preventDefault()

				// set a flag so we don't make suggestions again when the current_resource_text debounce fn runs
				this.prevent_current_resource_text_debounce = true
			}
		},

		spin_resource_icon(direction) {
			if (typeof(direction) != 'number') direction = 1
			this.resource_icon_rotation += direction * 360
		},

		export_alignments() {
			// TODO: show an interface to let the user choose:
			//   - which fields to export
			//   - whether or not to export rows for resources that don't have alignments
			//   - whether to export one row per alignment, or one row per resource with comma-separated URIs
			let arr = []

			arr.push([
				'Resource ID',
				'CASE URI(s)',
			])

			for (let r of this.resources) {
				if (r.alignments.length > 0) {
					let alignments = ''
					for (let a of r.alignments) {
						if (a.framework_identifier == this.framework_identifier) {
							let item = this.framework_record.cfo.cfitems[a.item_identifier]
							if (!empty(item)) {
								if (alignments) alignments += ','
								alignments += item.uri
							}
						}
					}
					if (alignments) {
						arr.push([r.external_resource_id, alignments])
					}
				}
			}

			console.log(arr)

			if (arr.length == 1) {
				this.$alert('No resource alignments to export!')
				return
			}

			let filename = sr('resource-alignments-$1.csv', this.framework_identifier)
			U.download_file(CSV.stringify(arr), filename)
		},

		generate_random_resource_id() {
			if (empty(this.resource_for_editing.external_resource_id)) {
				this.resource_for_editing.external_resource_id = U.new_uuid()
			}
		},

		show_alignment(alignment) {
			// if we're currently aligning and this alignment is to the tba_framework, use start_alignment instead
			if (this.tba_framework_identifier && alignment.framework_identifier == this.tba_framework_identifier) {
				this.start_alignment(alignment)
				return
			}

			let data = {
				framework_identifier: alignment.framework_identifier,
				item_identifier: alignment.item_identifier
			}
			let selected_items = []
			// send through any other items from this framework that are already aligned to this resource
			for (let alignment of this.current_resource.alignments) {
				if (alignment.framework_identifier == data.framework_identifier) selected_items.push(alignment.item_identifier)
			}
			if (selected_items.length > 0) data.selected_items = selected_items
			
			let show_data = { 
				// fn called when a new framework is chosen by the user (also called as soon as the iframe loads)
				// framework_chosen_callback_fn: (framework_identifier)=> {
				// },

				// fn called when embedded satchel is hidden
				embed_hide_callback_fn: (arg)=>{ 
					// // console.warn('embed_hide_callback_fn', arg)
					// if the user explicitly closed the chooser and we're in aligning mode, restart the alignment process
					if (arg == 'control-btn') {
						// ??? I don't think we actually want to do this...
						// if (this.tba_framework_identifier) this.$nextTick(x=>this.start_alignment())
					}
				},

				// fn called continuously while embedded satchel is open; if it returns true, embedded satchel will be closed
				hide_fn: ()=>{ 
					// if ($('.k-resource-view').is(':visible') == false) console.warn('hide: hide_fn (show_alignment)')
					return ($('.k-resource-view').is(':visible') == false) 
				},

				small_frame_top: 60,
				small_frame_width: this.satchel_embed_width
			}
			// console.warn('show: show_alignment')
			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: false})
				})
			})
		},

		auto_start_alignment() {
			if (this.auto_starting_alignment) {
				this.start_alignment({reset: true})
			}
		},

		start_alignment(params) {
			// console.warn('start_alignment', params)
			if (empty(params)) params = {}
			let data = {}

			// unless told to do so, don't reset what is currently showing
			if (params.reset) {
				data.force_framework_reset = true
			} else {
				data.no_framework_reset = true
			}

			if (params.framework_identifier) data.framework_identifier = params.framework_identifier
			else data.framework_identifier = this.tba_framework_identifier
			// data.framework_identifier = 'b87078f4-a87c-4fb2-9598-ad0c567d9615'	// TN sci test

			// array of cfitems that are currently aligned to the resource
			let selected_items = []
			if (params.selected_items) data.selected_items = params.selected_items
			else if (data.framework_identifier) {
				// send through any items from this framework that are already aligned to this resource
				for (let alignment of this.current_resource.alignments) {
					if (alignment.framework_identifier == data.framework_identifier) selected_items.push(alignment.item_identifier)
				}
				if (selected_items.length > 0) data.selected_items = selected_items
			}

			// array of candidate items that have been clicked by the user; leave at null if we don't have any
			if (this.candidates_showing_on_right.length > 0) {
				data.candidate_items = this.candidates_showing_on_right
			}

			// if we received an item_identifier, highlight and scroll to that item
			if (params.item_identifier) {
				data.item_identifier = params.item_identifier
				data.scroll_to_item_identifier = true
			}

			let show_data = { 
				// fn called when a new framework is chosen by the user (also called as soon as the iframe loads)
				framework_chosen_callback_fn: (framework_identifier)=> {
					this.tba_framework_identifier = framework_identifier
				},

				// fn called when embedded satchel is hidden
				embed_hide_callback_fn: (arg)=>{ 
					// console.warn('embed_hide_callback_fn', arg)
					// if the user explicitly closed the chooser, set auto_starting_alignment to false (if the chooser is autmatically closed via hide_fn (below), this won't happen)
					if (arg == 'control-btn') this.auto_starting_alignment = false 
				},

				// fn called continuously while embedded satchel is open; if it returns true, embedded satchel will be closed
				hide_fn: ()=>{ 
					// if ($('.k-resource-view').is(':visible') == false) console.warn('hide: hide_fn (start_alignment)')
					return ($('.k-resource-view').is(':visible') == false) 
				},

				small_frame_top: 60,
				small_frame_width: this.satchel_embed_width
			}

			// once user has clicked to start_alignment, assume they want to auto_start future alignments, until/unless they explicitly click to close the chooser
			this.auto_starting_alignment = true

			// console.warn('show: start_alignment')
			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					this.report_ms('start_alignment: framework loaded')
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// console.warn('aligned_item', aligned_item)
						let i = selected_items.findIndex(identifier=>identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							// if user previously chose this item, remove it
							this.report_ms('clear_alignments start')
							this.clear_alignments(this.current_resource, [aligned_item]).then(()=>{
								// spin the resource icon backwards, then re-start the alignment process with the first aligned_item's framework
								this.spin_resource_icon(-1)
								this.start_alignment({
									framework_identifier: data.framework_identifier
								})
							})

						} else {
							// else add it and when done...
							this.report_ms('make_alignments start')
							this.make_alignments(this.current_resource, [aligned_item]).then(()=>{
								// spin the resource icon, then re-start the alignment process with the aligned_item's framework and identifier
								this.spin_resource_icon()
								this.start_alignment({
									framework_identifier: data.framework_identifier,
									lsitem_identifier: aligned_item.cfitem.identifier
								})
							})
						}
					})
				})
			})
		},

		async make_suggestions() {
			this.set_component.get_resource_alignment_suggestions(this.current_resource)

			// // this rigamarole would get the loading indicator to show while we're making suggestions
			// currently we're not showing the loader; we just show a small div that says "computing suggestions"
			// BUT if a framework has to load, THAT will show the loader
			// U.loading_start('Computing suggestions…')
			// setTimeout(async x=>{
			// 	await this.set_component.get_resource_alignment_suggestions(this.current_resource)
			// 	U.loading_stop()
			// })
		},

		candidate_clicked(candidate) {
			let data = {
				framework_identifier: candidate.framework_identifier,
			}
			// if this candidate wasn't already clicked, add it to candidates_showing_on_right; otherwise remove it
			let index = this.candidates_showing_on_right.findIndex(x=>x == candidate.cfitem.identifier)
			if (index == -1) {
				this.candidates_showing_on_right.push(candidate.cfitem.identifier)
				// if it wasn't already clicked, we highlight and jump to the item
				data.item_identifier = candidate.cfitem.identifier

			} else {
				this.candidates_showing_on_right.splice(index, 1)
			}

			// call start_alignment either way, to reflect the updated candidates
			this.start_alignment(data)
		},

		candidate_alignment_changed(candidate) {
			if (candidate.currently_aligned) {
				this.make_alignments(this.current_resource, [candidate]).then(()=>{
					if (this.tba_framework_identifier) {
						// spin the resource icon, then re-start the alignment process with the aligned_item's framework and identifier
						this.spin_resource_icon()
						this.start_alignment({
							framework_identifier: candidate.framework_identifier,
							lsitem_identifier: candidate.cfitem.identifier
						})
					}
				})
			} else {
				this.clear_alignments(this.current_resource, [candidate]).then(()=>{
					if (this.tba_framework_identifier) {
						// spin the resource icon backwards, then re-start the alignment process with the first aligned_item's framework
						this.spin_resource_icon(-1)

						// also clear this item as a candidate
						let index = this.candidates_showing_on_right.findIndex(x=>x == candidate.cfitem.identifier)
						if (index > -1) {
							this.candidates_showing_on_right.splice(index, 1)
						}

						this.start_alignment({
							framework_identifier: candidate.framework_identifier
						})
					}
				})
			}
		},

		choose_base_item_for_alignment(row) {
			// row.ao.alignment is the alignment to the base framework
			// row.ao.alignment.item_identifier is item_id from the base framework
			// what we want to do here is show the suggestions that are closest to that item from the base framework, ignoring everything else...
			if (this.base_item_for_alignment?.identifier == row.ao.alignment.item_identifier) this.base_item_for_alignment = null
			else this.base_item_for_alignment = {
				identifier: row.ao.alignment.item_identifier,
				ao: row.ao,
				item_text: row.tba_item_text
			}
			this.make_suggestions()
		},
	}
}
</script>

<style lang="scss">
.k-resource-view {
	padding:16px 8px 12px 16px;
	margin:0 auto;
	font-size: 16px;

	.k-case-ie-line-label {
		width:110px;
		// align-self: flex-start;
		text-align:right;
		padding-right:8px;
	}

	.k-resource-alignment-maker-resource-fields {
		.k-case-ie-line {
			font-size:14px;
			margin-bottom:4px;
			align-items: flex-start;
		}

		.k-case-ie-line-label {
			width:112px;
			padding-top:5px;
			text-align:left;
			flex-shrink:0;
		}

		.k-case-ie-line-value {
			padding-top:6px;
		}
	}
}

.k-resource-view-current-alignment {
	display:flex;
	align-items:flex-start;
	// margin-left:32px;
	font-size:14px;
	line-height:18px;
	padding:4px 2px 3px 2px;
	border:1px solid transparent;
	border-radius:4px;
	margin-top:-1px;

	p { margin:0; }
}

.k-resource-view-current-alignment-nowrap {
	white-space:nowrap;
}

.k-resource-view-candidate-clicked {
	// border:1px solid #000;
	background-color:$v-amber-lighten-5;
	border-color:$v-amber-darken-3;
}

.k-resource-view-item-link p {
	margin:0;
}

.k-resource-view-item-link:hover {
	text-decoration:underline;
	cursor:pointer;
}

.k-suggestion-computing-flasher {
	background-color:$v-orange-darken-3;
	color:#fff;
	// animation doesn't seem to work here...
	// animation: suggestion_computing_flasher .5s infinite;
}
// @keyframes suggestion_computing_flasher {
// 	0%, 100% {
// 		opacity: 0;
// 		visibility: hidden;
// 	}
// 	50% {
// 		opacity: 1;
// 		visibility: visible;
// 	}
// }

</style>
