<template><div class="k-editor-wrapper-outer"><div class="k-editor-wrapper k-editor-wrapper-wide k-case-associations-maker" :class="top_css" :style="top_style_css">
	<v-btn class="k-editor-close-btn" small icon color="grey darken-2" @click.stop="cancel_edit"><v-icon>fas fa-times-circle</v-icon></v-btn>
	<div class="k-editor-title d-flex align-center">
		<div><v-icon class="mr-1">fas fa-arrows-alt-h</v-icon> Make Associations</div>
		<v-spacer/>
		<v-checkbox v-if="left_framework_identifier!=right_framework_identifier" class="mt-0 pt-0 d-inline-block" :disabled="left_framework_is_mirror" v-model="save_to_crosswalk_framework" hide-details><template v-slot:label><span style="font-size:14px; font-weight:normal; margin-left:-4px;">Save associations to dedicated crosswalk framework</span></template></v-checkbox>
		<v-spacer/>
		<v-icon @click="U.show_help('create_assoc')" class="k-editor-info-icon mr-2">fas fa-info-circle</v-icon>
	</div>
	<div class="k-case-item-editor-scrollable k-case-item-editor-scrollable-tallest" style="font-size:14px">
		<div class="k-case-ie-line mb-0">
			<div class="k-case-ie-line-label mr-2 text-right" style="width:84px;"><nobr>Associate with<br>items from:</nobr></div>
			<div class="mr-1">
				<FrameworkSwitcher2 btn_size="small" color="primary" 
					ref="right_framework_switcher"
					:current_selected_framework_identifier="right_framework_identifier" 
					@framework_selected="right_framework_identifier=$event" 
				/>
			</div>
			<div class="k-right-framework-title"
				v-if="right_framework_record" 
				v-html="right_framework_record.json.CFDocument.title"
				@click="$refs.right_framework_switcher.menu_showing=true"
			></div>

			<!-- <v-autocomplete v-model="right_framework_identifier" :items="frameworks" label="" outlined background-color="#fff" dense hide-details @change="right_framework_identifier_changed">
				<template v-slot:item="data"><v-list-item-content><v-list-item-title><b v-if="data.item.this_framework">THIS FRAMEWORK:</b> <span v-html="data.item.text"></span></v-list-item-title></v-list-item-content></template>
			</v-autocomplete> -->
			<v-tooltip bottom>
				<template v-slot:activator="{on}">
					<v-btn v-on="on" v-show="should_show_crosswalk_editor_button" class="ml-2" fab x-small color="primary" @click="open_crosswalk">
						<v-icon small>fas fa-shuffle</v-icon>
					</v-btn>
				</template>
				Open Crosswalk Editor
			</v-tooltip>
			<v-btn v-if="!lowest_ancestor" :disabled="assistant_stage=='choose_lowest_ancestor'" small color="#444" text class="ml-1 k-tight-btn" @click="initialize_lowest_ancestor_chooser"><v-icon x-small class="mr-1">fas fa-tree</v-icon>Limit To Node…</v-btn>
			<v-btn v-if="lowest_ancestor" small color="primary" text class="ml-1 k-tight-btn" @click="reset_lowest_ancestor"><v-icon x-small class="mr-1">fa fa-tree</v-icon>Reset Tree Node</v-btn>
		</div>
		<!-- <div class="k-case-ie-line" style="margin-top:-4px"><v-spacer/>
		<v-spacer/></div> -->

		<div class="k-case-ie-line mb-0 mt-2 pt-1" style="border-top:1px solid #ccc;">
			<div class="k-case-ie-line-label mr-2 text-right" style="width:84px"><nobr>Association<br>type:</nobr></div>
			<div :class="make_association_all_types_shown?'k-association-type-selectors-all-showing':''">
				<v-radio-group v-model="selected_association_type" row hide-details class="mt-0 ml-2">
					<v-radio background-color="#fff" class="mb-1" value="exactMatchOf"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['exactMatchOf']}}</v-icon>{{association_type_labels.exactMatchOf}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='ext:isNearExactMatch'" background-color="#fff" class="mb-1" value="ext:isNearExactMatch"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['ext:isNearExactMatch']}}</v-icon>{{association_type_labels['ext:isNearExactMatch']}}*</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='ext:isCloselyRelatedTo'" background-color="#fff" class="mb-1" value="ext:isCloselyRelatedTo"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['ext:isCloselyRelatedTo']}}</v-icon>{{association_type_labels['ext:isCloselyRelatedTo']}}*</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='ext:isModeratelyRelatedTo'" background-color="#fff" class="mb-1" value="ext:isModeratelyRelatedTo"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['ext:isModeratelyRelatedTo']}}</v-icon>{{association_type_labels['ext:isModeratelyRelatedTo']}}*</span></template></v-radio>
					<v-radio background-color="#fff" class="mb-1" value="isRelatedTo"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['isRelatedTo']}}</v-icon>{{association_type_labels.isRelatedTo}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='ext:hasSubstitute'" background-color="#fff" class="mb-1" value="ext:hasSubstitute"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['ext:hasSubstitute']}}</v-icon>{{association_type_labels['ext:hasSubstitute']}}*</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='replacedBy'" background-color="#fff" class="mb-1" value="replacedBy"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['replacedBy']}}</v-icon>{{association_type_labels.replacedBy}}</span></template></v-radio>
					<v-radio background-color="#fff" class="mb-1" value="precedes"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['precedes'][0]}}</v-icon>{{association_type_labels.precedes}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='isTranslationOf'" background-color="#fff" class="mb-1" value="isTranslationOf"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['isTranslationOf']}}</v-icon>{{association_type_labels.isTranslationOf}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='hasSkillLevel'" background-color="#fff" class="mb-1" value="hasSkillLevel"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['hasSkillLevel']}}</v-icon>{{association_type_labels.hasSkillLevel}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='isPartOf'" background-color="#fff" class="mb-1" value="isPartOf"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['isPartOf'][0]}}</v-icon>{{association_type_labels.isPartOf}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='exemplar'" background-color="#fff" class="mb-1" value="exemplar"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['exemplar']}}</v-icon>{{association_type_labels.exemplar}}</span></template></v-radio>
					<v-radio v-show="make_association_all_types_shown||selected_association_type=='isPeerOf'" background-color="#fff" class="mb-1" value="isPeerOf"><template v-slot:label><span class="k-association-type-selector-label"><v-icon x-small class="mr-1" style="margin-top:-2px;margin-left:-3px;" color="#000">fas fa-{{association_type_icons['isPeerOf']}}</v-icon>{{association_type_labels.isPeerOf}}</span></template></v-radio>


					<!-- <v-radio v-show="make_association_all_types_shown||selected_association_type=='r_precedes'" background-color="#fff" class="mb-1" value="r_precedes"><template v-slot:label><span class="k-association-type-selector-label">{{association_type_labels_reverse.precedes}}</span></template></v-radio> -->
					<!-- <v-radio v-show="make_association_all_types_shown||selected_association_type=='ext:hasNoMatch'" background-color="#fff" class="mb-1" value="ext:hasNoMatch"><template v-slot:label><span class="k-association-type-selector-label">{{association_type_labels['ext:hasNoMatch']}}</span></template></v-radio> -->
					<!-- <v-radio v-show="make_association_all_types_shown||selected_association_type=='r_hasSkillLevel'" background-color="#fff" class="mb-1" value="r_hasSkillLevel"><template v-slot:label><span class="k-association-type-selector-label">{{association_type_labels_reverse.hasSkillLevel}}</span></template></v-radio> -->
					<!-- <v-radio v-show="make_association_all_types_shown||selected_association_type=='r_isPartOf'" background-color="#fff" class="mb-1" value="r_isPartOf"><template v-slot:label><span class="k-association-type-selector-label">{{association_type_labels_reverse.isPartOf}}</span></template></v-radio> -->
					<!-- <v-radio v-show="make_association_all_types_shown||selected_association_type=='r_exemplar'" background-color="#fff" class="mb-1" value="r_exemplar"><template v-slot:label><span class="k-association-type-selector-label">{{association_type_labels_reverse.exemplar}}</span></template></v-radio> -->

					<div v-if="!make_association_all_types_shown"><v-btn x-small class="k-tight-btn k-nocaps-btn" color="#999" dark @click="make_association_all_types_shown=true">Show More Types</v-btn></div>
					<div v-if="make_association_all_types_shown" class="mt-2"><v-btn x-small class="k-tight-btn k-nocaps-btn" color="#999" dark @click="make_association_all_types_shown=false">Hide More Types</v-btn></div>
					<div v-if="make_association_all_types_shown" class="ml-3" style="font-size:14px; align-self:end;">*Extended association types</div>
				</v-radio-group>
			</div>
			<v-spacer/>

			<!-- btn to save a "no-match" assoc: if left node is selected, we're aligning to another framework, and we're saving to a crosswalk framework -->
			<div v-visible="left_node && left_framework_identifier!=right_framework_identifier && save_to_crosswalk_framework">
				<v-tooltip bottom><template v-slot:activator="{on}">
					<v-btn v-on="on" icon small color="red darken-3" dark @click="make_nomatch_assoc"><v-icon>fas fa-ban</v-icon></v-btn>
				</template>Explicitly specify that the left item<br>has NO MATCH with this framework</v-tooltip>
			</div>
		</div>

		<div class="k-associations-maker-created-msg my-2 px-2 py-1 elevation-2" :class="flashing?'k-associations-maker-created-msg-flashing':''">
			<div class="d-flex mt-2 mb-1 align-center">
				<!-- <div style="font-size:14px; line-height:18px; text-align:left;"><b>Suggest<br>associations:</b></div> -->
				<div>
					<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" small color="#fff" class="k-tight-btn mr-3">Suggestion Criteria</v-btn></template>
						<v-list dense>
							<v-list-item><v-list-item-title>
								<v-checkbox class="mt-0 pt-0 d-inline-block" style="vertical-align:bottom" v-model="auto_suggest" hide-details @click.stop=""><template v-slot:label><span style="font-size:14px">Auto-suggest when an item is chosen on the left</span></template></v-checkbox>
							</v-list-item-title></v-list-item>

							<v-divider />

							<v-list-item><v-list-item-title>
								<v-checkbox class="mt-0 pt-0 d-inline-block" style="vertical-align:bottom" v-model="ignore_education_level_mismatches" hide-details @click.stop=""><template v-slot:label><span style="font-size:14px">DON’T suggest items where education levels don’t match</span></template></v-checkbox>
							</v-list-item-title></v-list-item>

							<v-list-item><v-list-item-title>Make suggestions based on…</v-list-item-title></v-list-item>

							<v-list-item v-for="(mitem, key) in suggestion_criteria_descriptions" :key="key" @click.stop="set_suggestion_criteria(key)" v-if="show_suggestion_option(key)"><v-list-item-title>
								<v-icon small color="#555" class="mr-2 ml-3" style="margin-top:-2px">{{suggestion_criteria[key]?'fas fa-check-circle' : 'far fa-circle'}}</v-icon>
								<span v-html="mitem"></span>
							</v-list-item-title></v-list-item>

							<v-list-item v-if="assistant_suggestions.length>0" style="min-height:24px" class="pb-1"><v-list-item-title class="text-center">
								<v-btn x-small text color="primary" @click="clear_suggestions"><v-icon small class="mr-1">fas fa-broom-wide</v-icon> Clear Suggestions</v-btn>
							</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</div>
				<div class="mr-3" style="flex:1 1 auto">
					<v-text-field ref="suggestion_search_terms" background-color="#fff" outlined dense hide-details label="Search terms for suggestions…" v-model="keywords" placeholder="" rows="1" row-height="16" auto-grow autocomplete="new-password" clearable @keyup.enter="run_assistant"></v-text-field>
				</div>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" small class="k-tight-btn" color="primary" @click="run_assistant">Suggest<v-icon small class="ml-1">fas fa-arrow-right</v-icon></v-btn></template><div class="text-center" v-html="left_node?'Make suggestions based on selected criteria':'Choose an item on the left, then click here to make suggestions'"></div></v-tooltip>
			</div>

			<div v-show="created_msg" style="margin-top:12px; padding-top:12px; border-top:1px solid #999; padding-bottom:0px;">
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 float-right" x-small text color="secondary" @click="undo_last_assoc"><v-icon small class="mr-1">fas fa-undo-alt</v-icon>Undo</v-btn></template>Undo association</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on"><b>Association created</b> (hover for details)</div></template><div v-html="created_msg"></div></v-tooltip>
			</div>

			<div v-if="assistant_stage" class="k-association-assistant-instructions mt-1 mb-1 text-left">
				<div v-if="assistant_stage=='choose_lowest_ancestor'" class="text-center"><v-icon small class="mr-1" color="#555">fas fa-filter</v-icon> Choose (by checking a box below) the “lowest ancestor” of the items in the tree on the right that you want to consider for associations. <v-btn x-small color="secondary" @click="cancel_lowest_ancestor_chooser">Cancel</v-btn></div>

				<div v-if="assistant_suggestions.length>0" style="border-top:1px solid #999;" class="mt-1">
					<div class="my-1"><b>Top suggestions:</b> (click to reveal in tree)</div>
					<div v-for="(node, index) in assistant_suggestions" class="k-associations-maker-suggestion" @click="reveal_suggestion(node)">
						<v-tooltip bottom><template v-slot:activator="{on}"><span v-on="on" class="k-associations-maker-suggestion-sim-score" @click.stop="tooltip_clicked(node)"><v-icon x-small v-if="node.cat" small color="green lighten-3" style="margin-left:-3px; margin-right:3px; margin-bottom:-1px;">fas fa-check-circle</v-icon>{{raw_comp_score(node)}}</span></template><div v-html="node.comp_score_tooltip"></div></v-tooltip>
						<span class="k-associations-maker-suggestion-text" v-html="suggestion_html(node)"></span>
					</div>
					<div class="d-flex align-center mt-3" v-if="more_suggestions_available">
						<v-btn x-small color="secondary" @click="choose_assistant_suggestions">Show More Suggestions</v-btn>
					</div>
				</div>
				<div v-if="assistant_stage=='suggestions_showing' && assistant_suggestions.length==0" class="text-center"><i>No suggestions to show. Try changing the SUGGESTION CRITERIA<span v-if="lowest_ancestor"><br>or changing the tree node you’re limiting to (RESET TREE NODE)</span>.</i></div>
			</div>
		</div>

		<div v-if="right_framework_record" class="k-case-associations-maker-tree-holder">
			<CASETree ref="right_framework_component" :framework_record="right_framework_record" :show_chooser_fn="show_chooser_fn" :open_nodes_override="open_nodes_right" :featured_node_override="right_featured_node" :highlighted_identifier_override="highlighted_identifier_override"
			 	:chosen_items="chosen_items"
			/>
		</div>
	</div>
</div></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CASETree from '../CASEFrameworkViewer/CASETree'
import MakeAssociationsAssistantMixin from './MakeAssociationsAssistantMixin.js'
import FrameworkSwitcher2 from '../frameworks/FrameworkSwitcher2'

export default {
	components: { CASETree, FrameworkSwitcher2 },
	mixins: [MakeAssociationsAssistantMixin],
	props: {
		framework_record: { required: true },
		viewer: { required: false, default() { return ''} },
	},
	data() { return {
		right_framework_record: null,
		show_chooser_fn: false,
		left_node: null,
		right_node: null,
		right_node_component: null,
		created_msg: '',
		flashing: false,
		last_created_assoc: null,
		open_nodes_right: {},
		highlighted_identifier_override: '',
		association_saving: false,
		// chosen_items: [],
	}},
	computed: {
		...mapState(['framework_records', 'association_type_labels', 'association_type_labels_reverse', 'association_type_icons']),
		...mapGetters([]),
		framework_maximized() {
			if (empty(this.viewer)) return false
			return this.viewer.maximized
		},
		frameworks() {
			if (empty(this.framework_record)) return []

			let arr = []
			for (let fr of this.$store.getters.filtered_framework_records) {
				let doc = fr.json.CFDocument
				// skip crosswalk frameworks, sandboxes, and this framework
				if (doc.frameworkType == 'crosswalk') continue
				if (!empty(fr.ss_framework_data.sandboxOfIdentifier)) continue
				if (doc.identifier == this.framework_record.lsdoc_identifier) continue
				arr.push({value: doc.identifier, text: doc.title})
			}
			// sort by title
			arr.sort((a,b)=>U.natural_sort(a.text, b.text))
			// then add this framework at the start of the list
			arr.unshift({value: this.framework_record.lsdoc_identifier, this_framework:true, text: this.framework_record.json.CFDocument.title})
			return arr
		},
		left_framework_identifier() {
			// the framework showing on the left will usually be framework_record, but the user can switch to showing a different tree on the left
			return this.framework_record.document_identifier_showing_in_tree
		},
		left_framework_record() {
			return this.framework_records.find(x=>x.lsdoc_identifier == this.left_framework_identifier)
		},
		left_framework_is_mirror() {
			return this.left_framework_record.ss_framework_data.is_mirror === 'yes'
		},
		top_css() {
			const framework_color = U.framework_color(this.framework_record.cfo.cftree.cfitem.identifier)
			if (!isNaN(framework_color)) return 'k-framework-color-' + framework_color + '-editor'
			return ''
		},
		top_style_css() {
			return U.framework_color_object(this.framework_record.cfo.cftree.cfitem.identifier, 'editor')
		},
		selected_association_type: {
			get() { return this.$store.state.lst.make_association_association_type },
			set(val) { this.$store.commit('lst_set', ['make_association_association_type', val]) }
		},
		save_to_crosswalk_framework: {
			get() { 
				// currently, at least, for within-framework assocs, we must save to the framework itself
				if (this.left_framework_identifier == this.right_framework_identifier) return false

				// for mirrored frameworks, we must save to a crosswalk framework
				if (this.left_framework_is_mirror) return true

				// we have separate defaults for this for inter- and intra-framework associations
				if (this.right_framework_identifier == this.left_framework_identifier) {
					return this.$store.state.lst.make_association_save_to_crosswalk_framework_intra
				} else {
					return this.$store.state.lst.make_association_save_to_crosswalk_framework_inter
				}
			},
			set(val) { 
				if (this.left_framework_is_mirror) return

				if (this.right_framework_identifier == this.left_framework_identifier) {
					this.$store.commit('lst_set', ['make_association_save_to_crosswalk_framework_intra', val]) 
				} else {
					this.$store.commit('lst_set', ['make_association_save_to_crosswalk_framework_inter', val]) 
				}
			}
		},
		// dedicated crosswalk framework between the two frameworks being associated
		crosswalk_framework_record() {
			if (this.left_framework_identifier == this.right_framework_identifier) return null
			return U.get_crosswalk_framework_record(this.left_framework_identifier, this.right_framework_identifier)
		},
		// separate selected_association_type into the actual association type and whether or not the user created a "reverse" association
		associationType() {
			if (this.selected_association_type.search(/^r_(.*)/) > -1) return RegExp.$1
			else return this.selected_association_type
		},
		associationType_reversed() {
			if (this.selected_association_type.search(/^r_(.*)/) > -1) return true
			else return false
		},
		make_association_all_types_shown: {
			get() { return this.$store.state.lst.make_association_all_types_shown },
			set(val) { this.$store.commit('lst_set', ['make_association_all_types_shown', val]) }
		},
		right_framework_identifier: {
			// store the right_framework_identifier for each framework in localstorage
			// default value is the left_framework_identifier
			get() {
				let s = this.$store.state.lst.make_association_right_framework_identifier
				if (s) {
					let o = JSON.parse(s)
					return (o[this.left_framework_identifier]) ? o[this.left_framework_identifier] : this.left_framework_identifier
				} else return this.left_framework_identifier
			},
			set(val) {
				let o = {}
				let s = this.$store.state.lst.make_association_right_framework_identifier
				if (s) o = JSON.parse(s)
				o[this.left_framework_identifier] = val
				this.$store.commit('lst_set', ['make_association_right_framework_identifier', JSON.stringify(o)])
			},
		},
		// fill chosen_items with identifiers of items associated with the left_node that match the chosen association type (in either direction)
		chosen_items() {
			// if no left_node chosen or associationType isn't selected (which really shouldn't happen), no associations
			if (!this.left_node || !this.associationType) return []

			// get associations that include the left_node's item that match the associationType the user is trying to create
			let assocs = this.framework_record.json.CFAssociations.filter(x=>
				x.associationType == this.associationType
				&& (x.originNodeURI.identifier == this.left_node.cfitem.identifier
				|| x.destinationNodeURI.identifier == this.left_node.cfitem.identifier)
			)

			// add assocs from crosswalk framework
			if (this.crosswalk_framework_record) {
				assocs = assocs.concat(this.crosswalk_framework_record.json.CFAssociations.filter(x=>
					x.associationType == this.associationType
					&& (x.originNodeURI.identifier == this.left_node.cfitem.identifier
					|| x.destinationNodeURI.identifier == this.left_node.cfitem.identifier)
				))
			}

			let arr = []
			for (let assoc of assocs) {
				// push the identifier of the item on the other end to arr
				if (assoc.originNodeURI.identifier == this.left_node.cfitem.identifier) {
					arr.push(assoc.destinationNodeURI.identifier)
				} else {
					arr.push(assoc.originNodeURI.identifier)
				}
			}

			return arr
		},
		has_crosswalk_editor_access() {
			// const is_super_user = vapp.is_granted('super')
			// return is_super_user || this.$store.state.site_config.enable_crosswalk_editor_access == 'true'
			return this.$store.state.site_config.enable_crosswalk_editor_access == 'true'
			
		},
		should_show_crosswalk_editor_button() {
			const are_frameworks_different = this.left_framework_identifier != this.right_framework_identifier
			return this.has_crosswalk_editor_access && are_frameworks_different
		},
	},
	watch: {
		associationType() {
			this.$cancelSnackbars()
			this.created_msg = ''
			// when associationType is set, call make_association -- so if the user first clicks items on both sides, then clicks a type, an association will be created
			if (this.associationType) {
				this.make_association('type')
			}
		},
		right_framework_identifier: {immediate: true, handler(val) {
			// reset right_node if/when a new framework is specified
			this.right_node = null

			// also reset assistant mode
			this.assistant_enabled = false

			// also reset open_nodes_right
			this.open_nodes_right = {}

			let fr = this.framework_records.find(x=>x.lsdoc_identifier==this.right_framework_identifier)
			
			// if we don't find fr, maybe the last framework the person associated to has been deleted
			if (empty(fr)) return null

			// first load the framework from the server if needed
			U.load_framework_and_cfo(this.right_framework_identifier, {load_vectors: true, show_loader: true}).then(()=>{
				// then set right_framework_record and make sure this framework's associations are showing
				this.right_framework_record = fr
				this.viewer.update_association_display({framework_id: this.right_framework_identifier, associationType: this.associationType, actions: ['display']})
			})
		}},
	},
	created() {
		// stash a reference to the current_editor in viewer, so the viewer can determine whether or not to allow the user to switch to editing another item
		this.viewer.current_editor = this

		this.set_left_chooser_fn()
		this.set_right_chooser_fn()

		this.clear_left_selection()

		this.initialize_assistant()

		// debug
		vapp.make_associations_editor = this
	},
	mounted() {
	},
	methods: {
		open_crosswalk() {
			vapp.framework_list_component.open_crosswalk([this.left_framework_identifier, this.right_framework_identifier])
		},

		set_left_chooser_fn() {
			console.log('set_left_chooser_fn')
			this.viewer.set_show_chooser_fn((component, chosen_node, $event)=>{
				this.item_chosen_on_left(chosen_node, $event)
			})
		},
		set_right_chooser_fn() {
			this.show_chooser_fn = (component, chosen_node, $event)=>{
				this.item_chosen_on_right(component, chosen_node, $event)
			}
		},
		clear_left_chooser_fn() { this.viewer.set_show_chooser_fn(false) },
		clear_right_chooser_fn() { this.show_chooser_fn = false },

		cancel_edit() {
			// clear the suggestion fields
			this.reset_for_cancel_edit()

			// clear the chosen_node from the edited framework
			this.clear_left_selection()

			// clear the show_chooser_fn for the main (left) tree; this hides the btns receiving the checks
			this.viewer.set_show_chooser_fn(false)

			// clear this.viewer.current_editor
			this.viewer.current_editor = null

			this.$emit('dialog_cancel')
		},

		right_framework_identifier_changed() {
			// when we change the right framework, we have to reset_lowest_ancestor
			this.reset_lowest_ancestor()
		},

		clear_left_selection() {
			this.$store.commit('set', [this.left_framework_record, 'chosen_node', ''])
			this.left_node = null
		},

		clear_right_selection() {
			if (!empty(this.right_node_component)) this.right_node_component.node_is_chosen_local = false
			this.right_node_component = null
			this.right_node = null
		},

		item_chosen_on_left(node, $event) {
			this.created_msg = ''
			// when an item is selected on the left, we set left_framework_record.chosen_node to node; that makes the CASEItem show the checkmark for that node

			// if the same node is clicked twice, unselect it
			if (this.left_node == node) {
				this.clear_left_selection()
				return
			}

			// clear any existing suggestions
			this.clear_suggestions()

			// select the node -- for the left side set chosen_node of left_framework_record to select it
			this.$store.commit('set', [this.left_framework_record, 'chosen_node', node.tree_key+''])
			this.left_node = node

			// also make this the active node
			this.$store.commit('set', [this.left_framework_record, 'active_node', node.tree_key+''])

			// if both left and right nodes are selected
			if (this.left_node && this.right_node) {
				// then if we're not in assistant mode, make the association (in assistant mode you always choose the item on the left first, then the item on the right)
				if (!this.assistant_enabled) {
					this.make_association('left')
				}
			} else {
				// if the a right node isn't selected and auto_suggest is on, make suggestions, after a short delay
				if (this.auto_suggest) {
					setTimeout(x=>this.run_assistant(), 300)
				}
			}
		},

		item_chosen_on_right(component, node, $event) {
			this.created_msg = ''
			// when an item is selected on the right, we have to set node_is_chosen_local on the component to show the checkmark for that node

			// if the same node is clicked twice, all we do is unselect it
			if (this.right_node == node) {
				this.clear_right_selection()
				return
			}

			// first clear right selection
			this.clear_right_selection()

			// select the node -- for the right side, set node_is_chosen_local on the component to select it
			component.node_is_chosen_local = true
			this.right_node_component = component

			this.right_node = node

			// if both left and right nodes are selected, make an association (or clear it if it already exists)
			if (this.left_node && this.right_node) {
				this.make_association('right')
			}
		},

		// this allows the user to explicitly specify that there is no match from the left item to any item in the right framework
		// the button will only be shown if a) there is an item selected on the left, and b) we're associating to a different framework
		make_nomatch_assoc() {
			// if the left item has an assoc to something on the right, you can't do this (it doesn't make sense to say "this left item is associated to this right item" and "this left item has no matches to any item on the right")
			// displayed_associations_hash has an array of all assocs, but it may include assocs not with the left framework
			let displayed_assocs = this.left_framework_record.cfo.displayed_associations_hash[this.left_node.cfitem.identifier]
			if (displayed_assocs) {
				for (let assoc of displayed_assocs) {
					// if this is an existing nomatch to the right framework, warn (note we also do this in make_association)...
					if (assoc.destinationNodeURI.title == `NO MATCH (:${this.right_framework_record.lsdoc_identifier}:)`) {
						this.$alert('You’ve already created a “NO MATCH” association between the left item and this framework.')
						return
					}

					let right_identifier = (assoc.originNodeURI.identifier == this.left_node.cfitem.identifier) ? assoc.destinationNodeURI.identifier : assoc.originNodeURI.identifier
					if (this.right_framework_record.cfo.cfitems[right_identifier]) {
						// found an assoc with the right side
						this.$alert('You cannot specify that you have NO MATCH between the left item and this framework, because there IS ALREADY an association specified between the left item and an item in this framework.')
						return
					}
				}
			}

			// replicate the assoc we create for no_matches in CrosswalkEditor
			let right_node_uri = {
				title: `NO MATCH (:${this.right_framework_record.lsdoc_identifier}:)`,
				identifier: CFAssociation.nomatch_guid,
				uri: U.generate_child_uri(this.crosswalk_framework_record.json.CFDocument, CFAssociation.nomatch_guid, 'CFItems'),
			}

			this.save_association(right_node_uri, 'ext:hasNoMatch')
		},

		make_association(from) {
			// if we're still in the process of saving the last association, wait until that's finished before starting a new one
			if (this.association_saving) {
				console.log('blocking make_association temporarily...')
				setTimeout(x=>this.make_association(from), 50)
				return
			}

			this.created_msg = ''

			// if user just clicked a type and hasn't yet chosen nodes on both sides, just return
			if (from == 'type' && (empty(this.left_node) || empty(this.right_node))) return

			// else left and right nodes should be filled in; if type isn't selected, show an error (though this shouldn't happen anymore, since we now use isRelatedTo as the default association type)
			if (!this.associationType) {
				this.$inform({text:'Choose an Association Type to create an association between the two selected items.', snackbarTimeout: -1})
				return
			}

			let error_msg = ''

			// no associating a node with itself
			if (this.right_node.cfitem.identifier == this.left_node.cfitem.identifier) {
				error_msg = 'You cannot associate an item with itself!'
			}

			// for document nodes, you can only associate one document with another document
			if ((this.right_node.cfitem.title && !this.left_node.cfitem.title) || (this.left_node.cfitem.title && !this.right_node.cfitem.title)) {
				error_msg = 'You can only associate a document with another document (document-item associations, which you specified, aren’t valid).'
			}

			// check if this association already exists, in either direction. first check in the home framework...
			let assoc_check = this.framework_record.json.CFAssociations.find(x=>
				x.associationType == this.associationType && (
					   (x.originNodeURI.identifier == this.left_node.cfitem.identifier && x.destinationNodeURI.identifier == this.right_node.cfitem.identifier)
					|| (x.originNodeURI.identifier == this.right_node.cfitem.identifier && x.destinationNodeURI.identifier == this.left_node.cfitem.identifier)
				)
			)

			// then check in crosswalk framework if we have one
			if (!assoc_check && this.crosswalk_framework_record) {
				assoc_check = this.crosswalk_framework_record.json.CFAssociations.find(x=>
					x.associationType == this.associationType && (
						(x.originNodeURI.identifier == this.left_node.cfitem.identifier && x.destinationNodeURI.identifier == this.right_node.cfitem.identifier)
						|| (x.originNodeURI.identifier == this.right_node.cfitem.identifier && x.destinationNodeURI.identifier == this.left_node.cfitem.identifier)
					)
				)
			}

			if (assoc_check) {
				// if so, and if the from flag is 'right', remove the association
				if (from == 'right') {
					this.viewer.remove_association(assoc_check).then(x=>{
						this.last_created_assoc = null
						this.created_msg = ''
						this.clear_right_selection()
					})
					return
				} else {
					// else show an error
					let atl = (!this.associationType_reversed) ? this.association_type_labels[this.associationType] : this.association_type_labels_reverse[this.associationType]
					error_msg = sr('This association already exists:<br><br>$2 <i class="fas fa-long-arrow-alt-right"></i> <b>$1</b> <i class="fas fa-long-arrow-alt-right"></i> $3', atl, this.statement_abbreviation(this.left_node), this.statement_abbreviation(this.right_node))
				}
			}

			// if we're trying to make a "real" assoc and the user already has a "nomatch" assoc saved for this framework, warn the user and return
			let displayed_assocs = this.left_framework_record.cfo.displayed_associations_hash[this.left_node.cfitem.identifier]
			if (displayed_assocs) {
				for (let assoc of displayed_assocs) {
					// if this is an existing nomatch to the right framework, warn...
					if (assoc.destinationNodeURI.title == `NO MATCH (:${this.right_framework_record.lsdoc_identifier}:)`) {
						error_msg = 'You’ve specified that there is “NO MATCH” between the left item and this framework. You must remove the “NO MATCH” association before creating other associations.'
						break
					}
				}
			}

			// if this isn't a valid association...
			if (!empty(error_msg)) {
				console.log(error_msg)
				// alert the msg, then flash and clear one of the items
				this.$alert(error_msg).then(x=>{
					console.log(from)
					if (from == 'right') {
						this.flash_node(this.right_node)
						this.clear_right_selection()
					} else {
						this.flash_node(this.left_node)
						// leave the left node selected -- the user may have been viewing this node when they clicked to make associations
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			// if we get to here, save the association
			this.save_association()
		},

		save_association(right_node_uri, association_type_override) {
			// note that we use full_length titles in these associations, because if this is an association between an item in this framework A and another framework B, we don't want to have to load all the items in framework B in order to display the relationship when viewing framework A.
			// add ` (:framework-identifier:)` to the end of the titles, to make it easy to retrieve this data when showing the associations in the Satchel UI

			let association_framework_record
			if (!this.save_to_crosswalk_framework) {
				association_framework_record = this.framework_record
			} else {
				// if we're saving to a dedicated crosswalk framework, first see if we already the crosswalk framework record already exists
				if (this.crosswalk_framework_record) {
					association_framework_record = this.crosswalk_framework_record
				} else {
					// if not, create it now, then...
					U.create_crosswalk_framework(this.left_framework_record, this.right_framework_record).then(x=>{
						console.warn('created crosswalk framework!')
						// call update_frameworks_with_associations so that the viewer knows to show assocs from the crosswalk framework, then re-call save_association
						this.viewer.update_frameworks_with_associations()
						this.save_association()
					})
					return
				}
			}
			console.log(sr('save_association: $1 - $2', this.left_node.cfitem.humanCodingScheme, this.right_node?.cfitem.humanCodingScheme))

			// construct URI objects for left and right node
			// see similar code in ItemEditor
			let left_node_uri = {
				title: U.generate_cfassociation_node_uri_title(this.left_node.cfitem, true) + sr(' (:$1:)', this.left_framework_identifier),
				identifier: this.left_node.cfitem.identifier,
				uri: this.left_node.cfitem.uri,
			}

			// right_node_uri can be sent in (e.g. for creating a no_match assoc), but normally we create it here
			if (empty(right_node_uri)) right_node_uri = {
				title: U.generate_cfassociation_node_uri_title(this.right_node.cfitem, true) + sr(' (:$1:)', this.right_framework_identifier),
				identifier: this.right_node.cfitem.identifier,
				uri: this.right_node.cfitem.uri,
			}

			// construct association object, going left->right if the association isn't "reversed", or right->left if it *is* reversed
			let assoc = {}
			if (!empty(association_type_override)) assoc.associationType = association_type_override
			else assoc.associationType = this.associationType

			if (!this.associationType_reversed) {
				assoc.originNodeURI = left_node_uri
				assoc.destinationNodeURI = right_node_uri
			} else {
				assoc.originNodeURI = right_node_uri
				assoc.destinationNodeURI = left_node_uri
			}

			// fill in other parts of the CFAssociation object
			assoc = new CFAssociation(assoc)
			assoc.complete_data(association_framework_record.json.CFDocument)	// this will add '*NOW*' as the lastChangeDateTime
			assoc = assoc.to_json()

			let data = {
				lsdoc_identifier: association_framework_record.lsdoc_identifier,
				CFAssociations: [assoc],
			}

			// if we're saving to the crosswalk framework, send the document in case this is the first time we're saving the crosswalk framework
			if (this.save_to_crosswalk_framework) {
				let cfd = new CFDocument(this.crosswalk_framework_record.json.CFDocument)
				this.$store.commit('set', [this.crosswalk_framework_record.json, 'CFDocument', cfd.to_json()])
				data.CFDocument = cfd.to_json_for_update()

				// also have to send check_out_and_in for the crosswalk framework
				data.check_out_and_in = 'yes'
			}

			let doc_assoc
			// if the association involves a framework other than the "home" framework and we're saving the assoc in the home framework (as opposed to a crosswalk framework),
			if (this.right_framework_identifier != this.framework_record.lsdoc_identifier && association_framework_record.lsdoc_identifier == this.framework_record.lsdoc_identifier) {
				// see if we already have a document-document association with the other framework
				if (!this.framework_record.cfo.associated_documents.find(x=>x.identifier == this.right_framework_identifier)) {
					// if not, create one -- origin is the "home" framework; destination is the other framework

					// special case: if the user explicitly specified the documents as related to, the assoc created above will be the "opposite" association, so we *don't* want to save that one; save doc_assoc instead
					if (assoc.originNodeURI.identifier == this.right_framework_record.json.CFDocument.identifier && assoc.destinationNodeURI.identifier == this.left_framework_record.json.CFDocument.identifier && assoc.associationType == 'isRelatedTo') {
						data.CFAssociations = []
						assoc = null
					}

					doc_assoc = new CFAssociation({
						originNodeURI: {
							title: this.left_framework_record.json.CFDocument.title,
							identifier: this.left_framework_record.json.CFDocument.identifier,
							uri: this.left_framework_record.json.CFDocument.uri,
						},

						associationType: 'isRelatedTo',

						destinationNodeURI: {
							title: this.right_framework_record.json.CFDocument.title,
							identifier: this.right_framework_record.json.CFDocument.identifier,
							uri: this.right_framework_record.json.CFDocument.uri,
						},
					})
					doc_assoc.complete_data(this.framework_record.json.CFDocument)	// this will add '*NOW*' as the lastChangeDateTime
					doc_assoc = doc_assoc.to_json()
					data.CFAssociations.push(doc_assoc)
				}
			}

			// console.log(data)
			// note that we're saving an association, so we can block duplicate saves (search for association_saving)
			this.association_saving = true
			let assoc_right_node = this.right_node	// we have to remember this because we're going to clear it below
			data.show_spinner = false
			this.$store.dispatch('save_framework_data', data).then(()=>{
				// update timestamp with the value sent back from the server, then push to store
				if (assoc) {
					assoc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					this.$store.commit('set', [association_framework_record.json.CFAssociations, 'PUSH', assoc])

					// also update cfo.associations_hash
					U.update_associations_hash(association_framework_record.cfo, assoc)

					// set last_created_assoc, so that redo will work
					this.last_created_assoc = assoc
				}

				// do the same thing with doc_assoc if we created one
				if (doc_assoc) {
					doc_assoc.lastChangeDateTime = this.$store.state.framework_lastChangeDateTime
					this.$store.commit('set', [this.framework_record.json.CFAssociations, 'PUSH', doc_assoc])

					// this doesn't go in associations_hash, but add the other framework's document to this.framework_record.cfo.associated_documents
					this.$store.commit('set', [this.framework_record.cfo.associated_documents, 'PUSH', this.right_framework_record.json.CFDocument])

					// if we only set doc_assoc, set last_created_assoc to it, so that redo will work
					if (empty(assoc)) this.last_created_assoc = doc_assoc
				}

				// if we just made a "precedes" association to the same framework, 
				if (this.associationType == 'precedes' && this.right_framework_record == this.framework_record && this.former_right_node) {
					// reveal and select the item on the left that was just aligned to on the right -- setting up for the next item in the progression
					this.viewer.make_node_parents_open(this.former_right_node.tree_key)
					this.item_chosen_on_left(this.former_right_node)
					setTimeout(x=>{
						this.viewer.scroll_to_item(this.former_right_node.tree_key)
						this.former_right_node = null
					}, 300)
				} else {
					this.former_right_node = null
				}
				// else leave the left node selected -- the user may have been viewing this node when they clicked to make associations

				// update associations showing in the tree
				this.viewer.update_association_display({framework_id: this.right_framework_record.lsdoc_identifier, assoc: assoc, actions: ['add', 'display']})

				// set cat (current association type) value on node (used in the suggestions interface)
				if (assoc_right_node) this.$store.commit('set', [assoc_right_node, 'cat', assoc.associationType])
			})

			this.finish_association()
		},

		finish_association() {
			// flash the items and the association message
			this.flash_node(this.left_node)
			this.flash_node(this.right_node)
			// unless we're auto-suggesting and doing precedes associations, show a message saying what we just created (the message will get blown away if we're auto-creating), OR unless this was a no match (no right_node selected)
			if (!(this.auto_suggest && this.associationType == 'precedes') && !empty(this.right_node)) {
				let atl = (!this.associationType_reversed) ? this.association_type_labels[this.associationType] : this.association_type_labels_reverse[this.associationType]
				this.created_msg = sr('$2 <i class="fas fa-long-arrow-alt-right"></i> <b>$1</b> <i class="fas fa-long-arrow-alt-right"></i> $3', atl, this.statement_abbreviation(this.left_node), this.statement_abbreviation(this.right_node))
			}
			this.flashing = true

			// clear the right selection, after stashing it in case we need it to move to the that item on the left for a precedes association (see above)
			this.former_right_node = this.right_node
			this.clear_right_selection()

			setTimeout(x=>{
				this.flashing = false
			}, 600)
			
			// clear association_saving, so we can do another association if needed
			this.association_saving = false
		},

		undo_last_assoc() {
			this.viewer.remove_association(this.last_created_assoc).then(x=>{
				this.last_created_assoc = null
				this.created_msg = ''
			})
		},

		statement_abbreviation(node) {
			let s = ''
			if (node.cfitem.title) {
				s = node.cfitem.title
			} else {
				if (node.cfitem.humanCodingScheme) s = node.cfitem.humanCodingScheme + ' '
				s += node.cfitem.fullStatement
			}
			if (s.length > 30) s = s.substr(0, 29) + '…'
			return s
		},

		flash_node(node, multiple) {
			if (empty(node)) return
			this.$store.commit('set', [node, 'flashing', true])
			setTimeout(x=>this.$store.commit('set', [node, 'flashing', false]), 600)
		},
	}
}
</script>

<style lang="scss">
.k-case-associations-maker {
	.k-case-tree-scroll-wrapper {
		// adding padding-bottom of 100% makes scrolling to suggested items work consistently
		padding:0 0 100% 0;
		width: 100%;
		margin:0;
		position:relative;
		max-height:calc(100vh - 140px);

		.k-case-tree {
			margin-bottom:0;
			max-width:100%;
			box-shadow: none!important;
		}
	}

	textarea {
		font-size:12px;
		line-height:15px;
	}
}

.k-association-type-selector-label {
	white-space:nowrap;
	font-size:14px;
}

.k-association-type-selectors-all-showing {
	.k-association-type-selector-label {
		width:128px;
	}
}

.k-associations-maker-created-msg {
	// background-color:#ddd;
	background-color:$v-amber-lighten-3;
	border-radius:8px;
	text-align:center;
	transition: background-color 0.3s;
	.theme--light.v-label {
		color:#999;
	}
}

.k-associations-maker-created-msg-flashing {
	background-color:$v-amber-accent-4;
}

.k-association-assistant-instructions {
	border-radius:6px;
	padding:4px;
	transition: background-color 0.5s;
}

.k-associations-maker-suggestion {
	white-space:nowrap;
	width:100%;
	overflow:hidden;
	cursor:pointer;
	margin-bottom:2px;
	font-size:13px;

	.k-associations-maker-suggestion-sim-score {
		width:40px;
		display:inline-block;
		text-align:center;
		// font-size:13px;
		font-weight:bold;
		background-color:#444;
		color:#fff;
		border-radius:3px;
		text-decoration: none!important;
		margin-right:4px;

		.v-icon {
			font-size:11px!important;
			margin-right:2px;
			// margin-left:2px;
			margin-top:-3px;
		}
	}
}

.k-associations-maker-suggestion-text:hover {
	text-decoration: underline;
}

.k-case-associations-maker-jump-to-top {
	position:absolute;
	left:8px;
	bottom:8px;
}

.k-right-framework-title {
	font-size:14px;
	line-height:17px;
	background-color:#eee;
	cursor:pointer;
	padding:4px 6px;
	border-radius:4px;
	// font-weight: bold;
	// color:#444;
	// from gpt, which says this should work for chrome, safari, edge (not firefox)
	display: -webkit-box;
	-webkit-line-clamp: 2; /* Limit to 2 lines */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
