// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {
	methods: {
		view_resource_set(resource_set_id, $event, functionality_version) {
			// if functionality_version is provided, set in lst and use it; otherwise we'll use existing value from lst
			if (!empty(functionality_version)) {
				// values for version are '1' and '2'; v2 uses "x"-prefixed components; this is handled in router.js
				this.$store.commit('lst_set', ['resource_alignment_functionality_version', functionality_version])
			}

			// resources/37
			let url = '/resources'
			if (this.$store.state.lst.resource_alignment_functionality_version == '2') url += '2'
			url += '/' + resource_set_id

			// if user holds meta/ctrl key down, open in a new tab/window
			if (U.meta_or_alt_key($event)) {
				window.open(url)
			} else {
				this.$router.push({ path: url })
			}
		},
	}
}