<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="400" content-class="k-switcher-dialog" persistent scrollable><v-card class="pa-0"><v-card-text class="ma-0 pa-2 text-center" style="font-size:16px; line-height:20px;">
		<FrameworkSwitcher2 btn_size="large" color="primary" 
			:large_btn_content='`<i class=\"fas fa-arrow-right-arrow-left mr-2\"></i>Select a ${$store.state.site_config.framework_noun}…`'
			@framework_selected="set_framework_from_switcher" 
		/>
	</v-card-text></v-card></v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkSwitcher2 from './FrameworkSwitcher2'

export default {
	components: { FrameworkSwitcher2 },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		set_framework_from_switcher(selected_framework_identifier) {
			// this component is only shown (for now at least) if no framework is currently opened
			// changing, so set store.lst value if we're in embedded mode
			if (this.embedded_mode) this.$store.commit('lst_set', ['embedded_framework_identifier', selected_framework_identifier])

			// also store the new framework as recently-accessed
			U.add_to_recent_frameworks(selected_framework_identifier)

			// tell framework_list_component to load the framework
			vapp.framework_list_component.view_framework([selected_framework_identifier])

			this.$emit('dialog_cancel')
		},
	}
}
</script>

<style lang="scss">
.k-switcher-dialog {
	position:absolute;
	left:0;
	top:0;
}
</style>
