// Part of the SPARKL educational activity system, Copyright 2021 by Pepper Williams
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
		default_max_suggestions: 5,

		suggestion_criteria_descriptions: {
			fullStatement: 'Item statement matches',
			humanCodingScheme: 'Human-readable code matches',
			educationLevel: 'Education level matches',
			itemType: 'Item type matches',
			cross_assocs: 'Cross-associations',

			// things we're not doing anymore but could bring back (see code from pre-2025/04/04)
			// siblings: 'Sibling item statement matches',
			// parents: 'Parent item statement matches',
			// search: 'Search terms',
			// advanced: 'Use ADVANCED SEARCH<i class="fas fa-robot mx-2 grey--text text-darken-3"></i>'
		},

		// other things that are stored in suggestion_criteria include:
		//    auto_suggest
		//    ignore_education_level_mismatches

		suggestion_criteria_weights: {
			fullStatement: 100,
			humanCodingScheme: 100,
			educationLevel: 100,
			itemType: 100,
			cross_assocs: 100,
		},

		// TODO: make some of the following configurable?
		ignore_siblings: true,
		search_weighting: 0.5,
		hcs_num_mult: 6,		// setting hcs_num_mult and/and hcs_char_mult less than 10 scale partial matches so they aren't as influential
		hcs_char_mult: 6,

		assistant_stage: '',
		comp_score_arr: [],
		assistant_suggestions: [],
		n_all_suggestions: 0,
		revealed_suggestion_node: null,
		right_featured_node: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		suggestion_criteria() { return this.$store.state.lst.association_suggestion_criteria },
		more_suggestions_available() {
			return (this.n_all_suggestions > this.assistant_suggestions.length)
		},
		keywords: {
			get() { return this.$store.state.lst.make_association_keywords },
			set(val) { 
				this.$store.commit('lst_set', ['make_association_keywords', val]) 
				// set suggestion_criteria.search based on whether or not keywords is empty
				if (empty(val)) {
					this.set_suggestion_criteria('search', false)
				} else {
					this.set_suggestion_criteria('search', true)
				}
			}
		},
		lowest_ancestor: {
			// store the tree_key of the lowest_ancestor for each framework in localstorage
			// default value is null (not chosen)
			// note that here we reference the right framework, not the left framework
			get() {
				if (!this.right_framework_identifier || !this.right_framework_record) return null

				let s = this.$store.state.lst.make_association_lowest_ancestor
				if (s) {
					let o = JSON.parse(s)
					let tree_key = (o[this.right_framework_identifier]) ? o[this.right_framework_identifier] : null
					if (tree_key) return this.right_framework_record.cfo.tree_nodes_hash[tree_key*1]
					else return null
				} else return null
			},
			set(val) {
				// val should come in as empty or a node; get the tree_key if it's a node
				let tree_key = (val === null) ? '' : val.tree_key
				let o = {}
				let s = this.$store.state.lst.make_association_lowest_ancestor
				if (s) o = JSON.parse(s)
				o[this.right_framework_identifier] = tree_key
				this.$store.commit('lst_set', ['make_association_lowest_ancestor', JSON.stringify(o)])
			},
		},
		auto_suggest: {
			get() { return this.suggestion_criteria.auto_suggest },
			set(val) { this.set_suggestion_criteria('auto_suggest', val) },
		},
		ignore_education_level_mismatches: {
			get() { return this.suggestion_criteria.ignore_education_level_mismatches },
			set(val) { this.set_suggestion_criteria('ignore_education_level_mismatches', val) },
		},

		cross_assoc_base_framework_identifier() {
			// what we should be doing is getting the list of frameworks that the right-side framework has SME associations with, and letting the user choose from this list
			// for now we will totally hack this for use with WIDA
			if (this.right_framework_identifier == '97c883b4-8590-454f-b222-f28298ec9a81') {
				// go on the basis of the subject of the left_framework_record
				let subject = U.framework_subject_guess(this.left_framework_record.json.CFDocument)
				if (!subject) return ''
				subject = subject.subject_guess_string

				if (subject == 'English') return 'c64961be-d7cb-11e8-824f-0242ac160002'	// CC ELA
				if (subject == 'Math') return 'c6496676-d7cb-11e8-824f-0242ac160002'	// CC Math
				if (subject == 'Science') return '03e26f3e-b2f6-11e9-b654-0242ac150005'	// NGSS
				if (subject == 'Social Studies') return '34421374-5367-4a10-8197-68c5d492bfbf'	// C3
			}
			return ''
		},

		show_suggestion_option() { return (key) => {
			// don't show the educationLevel option if we're excluding altogether based on educationLevel
			if (key == 'educationLevel') {
				if (this.ignore_education_level_mismatches) return false
			}

			// and don't show the cross-associations option if we don't have any possible cross_assoc_base_framework_identifiers
			if (key == 'cross_assocs') {
				if (!this.cross_assoc_base_framework_identifier) return false
			}
			return true
		}},
	},
	watch: {
		right_node() {
			if (this.right_node != null && this.assistant_stage == 'choose_lowest_ancestor') {
				this.lowest_ancestor_chosen()
			}
		},
	},
	methods: {
		initialize_assistant() {
			if (this.lowest_ancestor) this.reset_to_lowest_ancestor()
			this.suggestion_criteria.search = !empty(this.keywords)
		},

		set_suggestion_criteria(key, val) {
			let o = Object.assign({}, this.suggestion_criteria)
			if (typeof(val) != 'boolean') val = !o[key]
			o[key] = val
			this.$store.commit('lst_set', ['association_suggestion_criteria', o]) 
		},

		clear_suggestions() {
			// note that clear_comp_scores clears out any checkmarks next to items associated to the previous left node selection
			this.clear_comp_scores()
			this.assistant_suggestions = []
			this.assistant_stage = ''
		},

		reset_for_cancel_edit() {
			this.clear_suggestions()
			this.clear_right_selection()
			this.open_nodes_right = {}
			this.right_featured_node = ''
		},

		reset_to_lowest_ancestor() {
			this.clear_suggestions()

			// make sure nothing is chosen on the right
			this.clear_right_selection()

			// hide all siblings of lowest_ancestor, and all siblings of lowest_ancestor's parents
			// (if there is no lowest ancestor, this means the top-level items in the framework will be left showing)
			if (this.lowest_ancestor) this.right_featured_node = this.lowest_ancestor.tree_key
			this.open_nodes_right = {}
			let parent_node = this.lowest_ancestor
			while (parent_node) {
				this.$set(this.open_nodes_right, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}
		},

		reset_lowest_ancestor() {
			this.lowest_ancestor = null
			this.clear_suggestions()
			this.clear_right_selection()
			this.open_nodes_right = {}
			this.right_featured_node = ''
		},

		initialize_lowest_ancestor_chooser() {
			if (this.lowest_ancestor) {
				this.reset_lowest_ancestor()
			} else {
				this.clear_right_selection()
				this.right_featured_node = ''
			}
			this.clear_left_selection()
			this.clear_suggestions()

			// hide chooser circles on the left
			this.clear_left_chooser_fn()

			this.$nextTick(x=>this.flash_assistant_instructions())
			this.assistant_stage = 'choose_lowest_ancestor'
		},

		cancel_lowest_ancestor_chooser() {
			this.reset_to_lowest_ancestor()
			// show chooser circles on the left again
			this.set_left_chooser_fn()
		},

		// user chose the lowest ancestor node
		lowest_ancestor_chosen() {
			// note the lowest ancestor node, then flash it
			this.lowest_ancestor = this.right_node

			this.flash_node(this.right_node, true)
			this.flash_assistant_instructions()

			this.reset_to_lowest_ancestor()

			// show chooser circles on the left again
			this.set_left_chooser_fn()

			// reset nodes again
			this.clear_left_selection()
			this.clear_right_selection()
		},

		async run_assistant() {
			if (!this.left_node) {
				this.$inform('You must choose an item on the left to have the system suggest associations on the right.')
				return
			}

			// reset things back to the lowest ancestor
			this.reset_to_lowest_ancestor()

			// get vectors for the left and right frameworks if we don't already have them
			await U.load_framework_and_cfo(this.left_framework_identifier, {load_vectors: true, show_loader: true})
			await U.load_framework_and_cfo(this.right_framework_identifier, {load_vectors: true, show_loader: true})

			// if we're using cross_assocs and we have a base...
			// TODO: for now, we're hacking this for WIDA...
			if (this.suggestion_criteria.cross_assocs && !empty(this.cross_assoc_base_framework_identifier)) {
				let cfr = U.get_crosswalk_framework_record(this.right_framework_identifier, this.cross_assoc_base_framework_identifier)
				if (cfr && !cfr.framework_json_loaded) {
					await this.$store.dispatch('get_lsdoc', cfr.lsdoc_identifier)
					this.$store.commit('set', [cfr, 'framework_json_loading', false])
				}
				this.cross_assoc_framework_record = cfr

				// we also need to make sure we have the crosswalked framework (e.g. ELA) and its vectors
				this.crosswalked_framework_record = await U.load_framework_and_cfo(this.cross_assoc_base_framework_identifier, {load_vectors: true, show_loader: true})
			}

			// if we have search terms, get vector for the terms
			this.keywords = $.trim(this.keywords)
			this.search_vector = null
			if (!empty(this.keywords)) {
				let payload = {
					service_url: 'sparkl_bot_vectors',
					strings: JSON.stringify([U.normalize_string_for_sparkl_bot(this.keywords)]),
				}
				U.loading_start()
				let result = await this.$store.dispatch('service', payload)
				U.loading_stop()
				if (result.vectors) {
					// we got the vector, so store it
					this.search_vector = result.vectors[0]
				} else {
					console.error('error getting vectors for search terms (1)')
					return
				}
			}

			// recursive fn to determine comps, for the right side -- start children of lowest_ancestor or the document node, then look at all descendents
			// this also initializes comp_score_arr, and calculates min/max search_match
			this.comp_score_arr = []
			this.min_search_match = 1000
			this.max_search_match = -1

			let parent = this.lowest_ancestor ?? this.right_framework_record.cfo.cftree
			for (let child of parent.children) {
				this.get_right_side_comps(child)
			}

			/////////////////////////////////////////////////
			let search_term_res
			if (this.suggestion_criteria.search && this.keywords) {
				search_term_res = U.create_search_re(this.keywords)
			}

			// go through each item we're checking
			let max_comp_score = 0
			for (let o of this.comp_score_arr) {
				let right_node = this.right_framework_record.cfo.tree_nodes_hash[o.tree_key+'']

				// see if the items are already associated; first look in the left framework_record, then in the crosswalk framework_record
				let assocs = this.framework_record.cfo.associations_hash[this.left_node.cfitem.identifier]
				if (assocs) {
					let ca = assocs.find(x=>x.destinationNodeURI.identifier == right_node.cfitem.identifier || x.originNodeURI.identifier == right_node.cfitem.identifier)
					if (ca) {
						this.$store.commit('set', [right_node, 'cat', ca.associationType])	// current association type
					}
				}
				if (empty(right_node.cat) && !empty(this.crosswalk_framework_record)) {
					// we don't have associations_hash for the crosswalk fr
					let ca = this.crosswalk_framework_record.json.CFAssociations.find(x=>
						(x.originNodeURI.identifier == this.left_node.cfitem.identifier && x.destinationNodeURI.identifier == right_node.cfitem.identifier)
						|| (x.originNodeURI.identifier == right_node.cfitem.identifier && x.destinationNodeURI.identifier == this.left_node.cfitem.identifier)
					)
					if (ca) {
						this.$store.commit('set', [right_node, 'cat', ca.associationType])	// current association type
					}
				}
				
				const search_match_range = this.max_search_match - this.min_search_match
				let add_factor_to_comp_score = (factor, o) => {
					// if we have search terms, use our search algorithm to boost matching items for HCS and FS
					if ((factor == 'humanCodingScheme' || factor == 'fullStatement') && this.keywords && search_term_res.length > 0 && this.right_framework_record.cfo.cfitems[o.identifier]) {
						let cfitem = this.right_framework_record.cfo.cfitems[o.identifier]

						// weight the original factor by 1 - search_weighting
						o.factors[factor] = Math.round((1 - this.search_weighting) * o.factors[factor])

						// for hcs, we just do a direct match
						if (factor == 'humanCodingScheme') {
							if (U.strings_match_search_term_res(search_term_res, [cfitem.humanCodingScheme])) {
								o.factors[factor] += Math.round(this.search_weighting * 100)
							}
						
						} else {
							// for fullStatement, start with the cosim value, weighted so that we make sure to pull the closest matches up and push the "farthest" matches down
							let x = (search_match_range == 0) ? 0 : (o.factors.search - this.min_search_match) / search_match_range * 100
							
							// if the term(s) are in the statement exactly, avg this value with 100
							if (U.strings_match_search_term_res(search_term_res, [cfitem.fullStatement])) {
								x = (x + 100) / 2
							}
							o.factors[factor] += Math.round(this.search_weighting * x)
						}
						// TODO: somewhere else, match identifier and sourceItemIdentifier if the search term is a GUID
					}

					// now add the scaled factor comp_score to comp_score_num, and update comp_score_den
					let den = this.suggestion_criteria_weights[factor]
					if (empty(o.factors[factor])) {
						console.warn('empty ' + factor)
					}
					o.comp_score_num += o.factors[factor] / 100 * den
					o.comp_score_den += den
				}

				if (this.suggestion_criteria.fullStatement) add_factor_to_comp_score('fullStatement', o)
				if (this.suggestion_criteria.parents) add_factor_to_comp_score('parents', o)
				if (this.suggestion_criteria.siblings) add_factor_to_comp_score('siblings', o)
				if (this.suggestion_criteria.humanCodingScheme) add_factor_to_comp_score('humanCodingScheme', o)
				if (this.suggestion_criteria.educationLevel && this.ignore_education_level_mismatches == false) add_factor_to_comp_score('educationLevel', o)
				if (this.suggestion_criteria.itemType) add_factor_to_comp_score('itemType', o)
				if (this.suggestion_criteria.cross_assocs && !empty(this.cross_assoc_base_framework_identifier)) add_factor_to_comp_score('cross_assocs', o)
				// if (this.keywords) add_factor_to_comp_score('search', o)
				
				// calculate final comp_score (comp_score_final will already be 0 if den is 0)
				if (o.comp_score_den > 0) {
					o.comp_score_final = Math.round(o.comp_score_num / o.comp_score_den * 100)
					// console.log(sr('$1 / $2 = $3', o.comp_score_num, o.comp_score_den, o.comp_score_final))
				}

				if (o.comp_score_final > max_comp_score) max_comp_score = o.comp_score_final

				// console.warn(object_copy(o))
			}

			for (let o of this.comp_score_arr) {
				let right_node = this.right_framework_record.cfo.tree_nodes_hash[o.tree_key+'']

				// set comp_score for the node, scaling by max_comp_score
				this.$store.commit('set', [right_node, 'comp_score', Math.round((o.comp_score_final / max_comp_score) * 100)])

				// also set comp_score_html -- shown as the tooltip when the user hovers over the comp_score indicator
				this.$store.commit('set', [right_node, 'comp_score_tooltip', this.comp_score_tooltip(o, right_node.cat)])
			}
	
			// sort scores, with highest first
			this.comp_score_arr.sort((a,b)=>b.comp_score_final-a.comp_score_final)

			// choose the assistant_suggestions to show
			this.choose_assistant_suggestions()

			// show the first suggestion in the tree that isn't already associatied
			if (this.assistant_suggestions.length > 0) {
				let i
				for (i = 0; i < this.assistant_suggestions.length; ++i) {
					if (!this.assistant_suggestions[i].cat) break
				}
				if (i < this.assistant_suggestions.length) this.reveal_suggestion(this.assistant_suggestions[i])
			}

			// console.log('W.5.2: ' + this.comp_score_arr.findIndex(x=>this.right_framework_record.cfo.cfitems[x.identifier].humanCodingScheme == 'W.5.2'))

			// note that comp_scores are displayed in CASEItem.vue
			this.assistant_stage = 'suggestions_showing'

			this.$nextTick(x=>this.flash_assistant_instructions())
		},

		get_right_side_comps(right_node) {
			// if this right item *is* the left item (or an alias of the left item), skip both it and its children
			if (right_node.cfitem.identifier == this.left_node.cfitem.identifier) return false

			// if ignore_siblings is true and this right item is a sibling of the left item, skip both it and its children
			if (this.ignore_siblings && right_node.parent_node && right_node.parent_node.children.find(x=>x == this.left_node)) return false

			let process_item = true
			// skip the document node if we get it (just process the document node's children)
			if (!right_node.cfitem.fullStatement) process_item = false
			
			// also skip aliases -- that is, only process each *item* once, even if the item appears in multiple places, because associations are item->item
			if (this.comp_score_arr.includes(x=>x.identifier == right_node.cfitem.identifier)) process_item = false

			// if we're ignoring education level mis-matches and they mismatch, don't process the item
			if (this.ignore_education_level_mismatches) {
				if (U.educationLevel_distance(right_node.cfitem.educationLevel, this.left_node.cfitem.educationLevel) > 0) process_item = false
			}
			
			if (process_item) {
				// always initialize the comp_score_arr object
				let o = {
					tree_key: right_node.tree_key,
					identifier: right_node.cfitem.identifier,
					factors: {},
					comp_score_num: 0,
					comp_score_den: 0,
					comp_score_final: 0,
				}
				this.comp_score_arr.push(o)

				// educationLevel match
				if (this.suggestion_criteria.educationLevel) {
					// except that if we're ignoring ed level mismatches, don't also use educationLevel as a factor
					if (this.ignore_education_level_mismatches == false) {
						o.factors.educationLevel = this.get_education_level_match(right_node)
					}
				}

				// humanCodingScheme match
				if (this.suggestion_criteria.humanCodingScheme) {
					o.factors.humanCodingScheme = this.get_hcs_match(right_node)
				}

				// itemType match
				if (this.suggestion_criteria.itemType) {
					// if left node doesn't have an itemType, ignore it
					let left_itemType = U.item_type_string(this.left_node.cfitem)
					if (left_itemType) {
						// match is either 100 or 0
						o.factors.itemType = (left_itemType == U.item_type_string(right_node.cfitem)) ? 100 : 0
					}
				}

				// get vectors for the left- and right-side nodes
				let right_node_vector, left_node_vector
				if (this.right_framework_record.sparkl_bot_vectors) right_node_vector = this.right_framework_record.sparkl_bot_vectors[o.identifier]
				if (this.left_framework_record.sparkl_bot_vectors) left_node_vector = this.left_framework_record.sparkl_bot_vectors[this.left_node.cfitem.identifier]

				// search match
				if (this.search_vector && right_node_vector) {
					o.factors.search = Math.round(U.cosine_similarity(right_node_vector, this.search_vector, true) * 100)
					if (o.factors.search < this.min_search_match) this.min_search_match = o.factors.search
					if (o.factors.search > this.max_search_match) this.max_search_match = o.factors.search
				}

				// fullStatement match
				if (this.suggestion_criteria.fullStatement && left_node_vector) {
					o.factors.fullStatement = Math.round(U.cosine_similarity(right_node_vector, left_node_vector, true) * 100)
				}

				// not doing parents and siblings for now...

				// cross_assocs
				if (this.suggestion_criteria.cross_assocs && this.crosswalked_framework_record?.sparkl_bot_vectors) {
					// example: left fw is Alaska ELA; right fw is WIDA; cross_assoc fw is CCELA
					// Go through all SME assocs to this item (right_node) from the "base framework"...
					let highest_cosim = 0
					let highest_cosim_cfitem
					for (let assoc of this.cross_assoc_framework_record.json.CFAssociations) {
						if (assoc.associationType == 'isChildOf') continue
						if (assoc.destinationNodeURI.identifier == right_node.cfitem.identifier || assoc.originNodeURI.identifier == right_node.cfitem.identifier) {
							// this is an assoc to the right_node; get the associated cfitem
							let assoc_cfitem_identifier = (assoc.originNodeURI.identifier == right_node.cfitem.identifier) ? assoc.destinationNodeURI.identifier : assoc.originNodeURI.identifier
							let assoc_cfitem = this.crosswalked_framework_record.cfo.cfitems[assoc_cfitem_identifier]
							if (empty(assoc_cfitem)) { console.warn('no assoc_cfitem found'); continue; }	// shouldn't happen

							// if we're ignoring ed level mismatches and this item doesn't match the left_node's ed level, don't consider it (we could consider, say, 1 or two levels off and scale...)
							if (this.ignore_education_level_mismatches == true) {
								if (U.educationLevel_distance(assoc_cfitem.educationLevel, this.left_node.cfitem.educationLevel) > 0) {
									continue
								}									
							}

							let assoc_item_vector = this.crosswalked_framework_record.sparkl_bot_vectors[assoc_cfitem.identifier]
							if (empty(assoc_item_vector)) { console.warn('no assoc_item_vector found'); continue; }	// shouldn't happen

							// Calculate cosim between left_node vector and the associated item vector
							let cosim = Math.round(U.cosine_similarity(left_node_vector, assoc_item_vector, true) * 100)

							// if both sides have humanCodingSchemes that are exactly the same, and...
							let left_hcs = this.left_node.cfitem.humanCodingScheme
							let ca_hcs = assoc_cfitem.humanCodingScheme
							if (!empty(left_hcs) && !empty(ca_hcs) && left_hcs == ca_hcs) {
								// ... the code has at least one letter, one number, and one decimal or dash...
								if (left_hcs.search(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[.-]).+$/) > -1) {
									// then boost the cosim as follows
									cosim = Math.round((200 + cosim) / 3)
								}
							}

							// highest cosim is the “cross_assocs” factor for this right_side_comp		
							if (cosim > highest_cosim) {
								highest_cosim = cosim
								highest_cosim_cfitem = assoc_cfitem
							}
						}
					}

					// store highest_cosim (which could be 0 if there weren't any cross-assocs) as the cross_assoc factor
					o.factors.cross_assocs = highest_cosim
					o.highest_cosim_cfitem = highest_cosim_cfitem
					// console.warn('highest_cosim: ' + highest_cosim)
				}
			}

			for (let child of right_node.children) {
				this.get_right_side_comps(child)
			}
		},

		get_education_level_match(right_node) {
			let lel = this.left_node.cfitem.educationLevel
			let rel = right_node.cfitem.educationLevel
			// if left node doesn't have an educationLevel, or if it's a range > 7 grade levels wide, ignore it
			if (lel && lel.length > 0 && lel.length <= 7) {
				// left_node has educationLevel(s); see if right_node has educationLevel(s) with a range <= 7 grade levels too
				if (rel && rel.length > 0 && rel.length <= 7) {
					// both sides have an el. look for overlap
					for (let ell of lel) {
						ell = (ell+'').toLowerCase()
						// if we find an overlapping grade...
						if (rel.findIndex(elr=>(elr+'').toLowerCase() == ell) > -1) {
							// set to 100 if the two spans match exactly (including if they're both a single grade)
							if (lel[0] == rel[0] && lel[lel.length-1] == rel[rel.length-1]) {
								return 100
							// or 100 if one side is a range and the other side is a single value in the range
							} else if (lel.length == 1 || rel.length == 1) {
								return 100
							// or 75 otherwise (some overlap of two ranges)
							} else {
								return 75
							}
						}
					}
					// if we didn't return a value above...
					let o0 = {index:0}
					let lower_left = this.$store.state.grades.find(x=>x.value == lel[0]) ?? o0
					let upper_left = this.$store.state.grades.find(x=>x.value == lel[lel.length-1]) ?? o0
					let lower_right = this.$store.state.grades.find(x=>x.value == rel[0]) ?? o0
					let upper_right = this.$store.state.grades.find(x=>x.value == rel[rel.length-1]) ?? o0
					// calculate diff, e.g. if left is 5 and right is 6, this will give us 1; if left is K and right is 8, we'll get 9
					let diff = Math.abs(((upper_left.index + lower_left.index) / 2 - (upper_right.index + lower_right.index) / 2))

					if (diff > 5) return 0
					else return 100 * (11 - diff) / 10 - 50
					// the above algorithm makes it so if grade is off by 1, we get 50, off by 2, we get 40, and so on
				}
			}

			// if we get to here, one side or the other doesn't have an educationLevel, so return 0
			return 0
		},

		get_hcs_match(right_node) {
			let left_hcs = this.left_node.cfitem.humanCodingScheme

			// if left node doesn't have a hcs, match is 100 if the right side also doesn't have an hcs, or 0 otherwise
			if (!left_hcs) {
				return (right_node.cfitem.humanCodingScheme) ? 0 : 100
			}

			// else left node has an hcs, so if right node doesn't have one, match is 0
			if (!right_node.cfitem.humanCodingScheme) {
				return 0
			}

			// if they are *identical*, return 100
			if (left_hcs == right_node.cfitem.humanCodingScheme) return 100

			// helper fn
			function split_to_segs(hcs) {
				let a1 = hcs.split(/\b/)
				// convert numbers, K/PK, and single lower-case letters to numeric values
				for (let i = 0; i < a1.length; ++i) {
					let s = a1[i]
					if (is_numeric(s)) a1[i] = s * 1
					else if (s == 'K') a1[i] = 0
					else if (s == 'PK') a1[i] = -1
					else if (s.length == 1 && s >= 'a' && s <= 'z') a1[i] = s.charCodeAt(0) - 96 	// 'a' == 1
				}

				let arr = []
				for (let i = 0; i < a1.length; ++i) {
					let s = a1[i]

					// combine numbers separated by dashes into the average of the two numbers
					if ((s == '-' || s == '–') && arr.length > 0 && a1.length > i+1 && is_numeric(arr[arr.length-1]) && is_numeric(a1[i+1])) {
						arr[arr.length-1] = ((arr[arr.length-1]*1 + a1[i+1]*1) / 2) + ''
						++i
					} else if ([' ', '.', '-', '–', ':'].includes(s)) {
						continue
					} else {
						arr.push(s+'')
					}
				}
				return arr
			}

			// split codes into segments, then for each segment...
			let left_segs = split_to_segs(left_hcs)
			let right_segs = split_to_segs(right_node.cfitem.humanCodingScheme)
			let num = 0
			let den = 0
			for (let i = 0; i < left_segs.length || i < right_segs.length; ++i) {
				den += 1
				// if either segment is empty, the other must be filled, so num+=0
				if (!left_segs[i] || !right_segs[i]) continue	

				let ln = left_segs[i]
				let rn = right_segs[i]

				// if segments are exactly the same...
				if (ln == rn) {
					// if they're numeric, or a single lower-case letter, +0.75 (could be configurable); or +1 otherwise
					// (we make it less than 1 so that non-numeric matches weight higher; but note that we need to arrange things so that this value is greater than the maximum num addend for a number that differs by 1, below
					if (is_numeric(ln) || ln.search(/^[a-z]/) > -1) num += 0.75
					else num += 1

				} else {
					// if both segments are numbers or single a-z characters (which would have been converted above), 
					if (is_numeric(ln) && is_numeric(rn)) {
						// compare by number/char sequence; by capping the match value at 5, we increase the separation between immediately adjacent grades (e.g. RL.5.3 - RL.6.3) and more distant grades (e.g. RL.5.3 - RL.7.3)
						let val = Math.abs(ln - rn)
						if (val > 5) val = 5
						num += ((5 - val) / 50) * this.hcs_num_mult

						// give a tiny bit higher weight to an "up" than a "down"
						if (rn > ln) num += 0.05

					} else {
						// else split into one-character "words" and use string_similarity_words to compare
						num += (U.string_similarity_words(ln.split('').join(' '), rn.split('').join(' '))) / 10 * this.hcs_char_mult
					}
				}
			}

			return Math.round((num / den) * 100)
		},

		choose_assistant_suggestions() {
			// if we're currently showing some suggestions, we want to show more; otherwise start with default_max_suggestions
			let max_suggestions
			if (this.assistant_suggestions.length < this.default_max_suggestions) max_suggestions = this.default_max_suggestions
			else max_suggestions = this.assistant_suggestions.length + 10

			this.assistant_suggestions = []

			let n_all_suggestions = 0	// count these as we go
			let last_comp_score = -1
			for (let i = 0; i < this.comp_score_arr.length; ++i) {
				let tree_key = this.comp_score_arr[i].tree_key
				let comp_score = this.comp_score_arr[i].comp_score_final
				let node = this.right_framework_record.cfo.tree_nodes_hash[tree_key+'']

				// never suggest comp_scores of 0; if we get to a 0, break, because since we're sorted descending, nothing else is going to be > 0
				if (comp_score == 0) break

				// this is a possible suggestion, but we might not add it...
				++n_all_suggestions

				// if we have enough suggestions, continue
				if (this.assistant_suggestions.length >= max_suggestions) {
					// UNLESS this comp_score is identical to the last_comp_score, in which case show this one too
					if (comp_score != last_comp_score) {
						continue
					}
				}

				// if we get to here, add this item as a suggestion
				this.assistant_suggestions.push(node)
				last_comp_score = comp_score
			}

			// mark suggestions as highlighted
			for (let node of this.assistant_suggestions) {
				this.$store.commit('set', [node, 'comp_score_highlighted', true])
			}

			// set n_all_suggestions
			this.n_all_suggestions = n_all_suggestions
		},

		suggestion_html(node) {
			return U.generate_cfassociation_node_uri_title(node.cfitem, true)
		},

		comp_score_tooltip(o, current_association_type) {
			let html = ''

			if (current_association_type) {
				html += `<b>ITEMS ARE ALREADY ASSOCIATED (${this.$store.state.association_type_labels[current_association_type]})</b><br>`
			}

			if (o.factors.education_level === 0 && this.ignore_education_level_mismatches) {
				html += 'Education levels do not match'
				return html
			}

			let elements = []
			for (let factor in this.suggestion_criteria_descriptions) {
				if (!empty(o.factors[factor])) {
					elements.push(`<li data-sort="${o.factors[factor]}">${o.factors[factor]}: ${this.suggestion_criteria_descriptions[factor]}</li>`)
				}
			}

			// elements.sort()
			html += '<ul class="mb-1">'
			html += elements.join('')

			html += '</ul>'
			html += `Overall Match Score: <b>${o.comp_score_final} / 100</b>`

			if (o.factors.cross_assocs > 0) {
				if (empty(o.highest_cosim_cfitem)) {
					console.warn('couldn’t find o.highest_cosim_cfitem')	// shouldn't happen
				} else {
					html += `<div class="mt-1 pt-1" style="border-top:1px solid #fff; margin-bottom:2px; max-width:400px; line-height:17px;">Cross-assoc: ${U.generate_cfassociation_node_uri_title(o.highest_cosim_cfitem, 100, true)}</div>`
					html += `<div class="text-center"><i>Click for full cross-assoc</i></div>`
				}
			}

			return html
		},

		raw_comp_score(node) {
			return this.comp_score_arr.find(x=>x.tree_key == node.tree_key).comp_score_final
		},

		make_association_from_shortcut() {
			if (!this.revealed_suggestion_node || !this.left_node) {
				return	// too hard to explain what went wrong...
			}
			this.right_node = this.revealed_suggestion_node
			this.make_association('right')
		},

		tooltip_clicked(node) {
			// get from the node to the comp_score_arr value
			let o = this.comp_score_arr.find(x=>x.tree_key == node.tree_key)

			// if we don't have a cross_assoc, just reveal the suggestion
			if ((o.factors.cross_assocs ?? 0) == 0 || empty(o.highest_cosim_cfitem)) {
				this.reveal_suggestion(node)
			} else {
				// otherwise show the crosswalk in a dialog, with a link to open in a new window
				let crosswalked_item = o.highest_cosim_cfitem
				let left_item = this.left_node.cfitem
				let s = ''
				
				s += `<div style="color:#555; font-size:14px"><b>${this.left_framework_record.json.CFDocument.title}</b></div>`
				s += `<div class="d-flex mt-1">`
				if (!empty(left_item.humanCodingScheme)) s += `<b style="margin-right:6px">${left_item.humanCodingScheme}</b>`
				s += `<div>${U.marked_latex(left_item.fullStatement)}</div>`
				s += `</div>`

				s += `<div class="mt-2 pt-2" style="border-top:1px solid #999; color:#555; font-size:14px"><b>${this.crosswalked_framework_record.json.CFDocument.title}</b></div>`
				s += `<div class="d-flex mt-1">`
				if (!empty(crosswalked_item.humanCodingScheme)) s += `<b style="margin-right:6px">${crosswalked_item.humanCodingScheme}</b>`
				s += `<div>${U.marked_latex(crosswalked_item.fullStatement)}</div>`
				s += `</div>`

				this.$confirm({
					title: `Cross-Association (${o.factors.cross_assocs} / 100)`,
					text: s,
					dialogMaxWidth: 640,
					cancelText: 'Open Cross-Assoc in new window'
				}).catch(x=>{
					let url = `/${this.crosswalked_framework_record.lsdoc_identifier}/${crosswalked_item.identifier}`
					window.open(url, 'crosswalked_item')
				})
			}
		},

		reveal_suggestion(node) {
			// start by hiding all nodes
			this.open_nodes_right = {}

			// open the suggested node
			let parent_node = node.parent_node
			while (!empty(parent_node)) {
				this.$set(this.open_nodes_right, parent_node.tree_key+'', true)
				parent_node = parent_node.parent_node
			}

			// highlight it and set revealed_suggestion_node (setting highlighted_identifier_override will ensure that the item's statement will be wrapped)
			this.highlighted_identifier_override = node.cfitem.identifier
			this.revealed_suggestion_node = node

			// scroll to it if necessary
			this.$nextTick(x=>{
				let node_jq = $(this.$el).find(sr('[data-case-tree-item-tree-key=$1]', node.tree_key))
				if (node_jq.length == 0) {
					console.log('can’t scroll in reveal_suggestion')
					return
				}

				// make sure the suggested item is showing in its tree-scroll-wrapper
				let $ctsr = node_jq.parents('.k-case-tree-scroll-wrapper')
				vapp.$vuetify.goTo(node_jq[0], {container: $ctsr[0], offset:50, duration:200})
			})
		},

		clear_comp_scores(node) {
			if (empty(node)) {
				if (!this.right_framework_record) return
				if (!empty(this.lowest_ancestor)) node = this.lowest_ancestor
				else node = this.right_framework_record.cfo.cftree
			}

			this.$store.commit('set', [node, 'comp_score', -1])
			this.$store.commit('set', [node, 'comp_score_tooltip', -1])
			this.$store.commit('set', [node, 'comp_score_highlighted', false])
			this.$store.commit('set', [node, 'cat', ''])	// current association type
			for (let child of node.children) {
				this.clear_comp_scores(child)
			}

			this.highlighted_identifier_override = ''
			this.revealed_suggestion_node = null
		},

		flash_assistant_instructions() {
			$('.k-association-assistant-instructions').addClass('k-associations-maker-created-msg-flashing')

			setTimeout(x=>{
				$('.k-association-assistant-instructions').removeClass('k-associations-maker-created-msg-flashing')
			}, 1000)
		},
	}
}
